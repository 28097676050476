/*
PageResponse component is designed for reuse in different response pages like Pass Qualification, Not Qualification, 
Email Qualification, Varification requaired, Email vaification failed, 404 Not Found.
*/

import React from "react";
import "./PageResponse.css";

const PageResponse = (props) => {
  return (
    <div className="main-page-response">
      <div className="horizontal-line" />
      <div className="page-response-container">
        <div id="left-item">
          <img
            src={props.imageURL}
            className="page-response-img"
            alt={props.alt}
          />
        </div>
        <div
          id="right-item"
          className={
            props.title === "Oops!"
              ? "page-response-info"
              : "page-response-info qualified"
          }
        >
          {props.title && <h1>{props.title}</h1>}
          {props.description && <>{props.description}</>}
          {props.descriptionTwo && <>{props.descriptionTwo}</>}
          {props.btntext && (
            <button
              className="page-response-btn"
              type="button"
              onClick={props.onClick}
            >
              {props.btntext}
            </button>
          )}
        </div>
      </div>
      <div className="horizontal-line" />
    </div>
  );
};

export default PageResponse;
