/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import AddressPopup from "../components/AddressPopup.js";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./UpdateAddress.css";
import { useSelector, useDispatch } from "react-redux";
import { storeCredentials } from "../redux/actions.js";
import { getCredentialsState } from "../redux/selectors";
import TimerComponent from "../components/TimerComponent.js";
import {
  refreshAndCallWithIdToken,
  REFRESH_TOKEN_TIME,
} from "../helpers/credentialsHelper.js";
import SpotsPopup from "../components/SpotsPopup.js";
import UserFlowBar from "../components/UserFlowBar.js";
import { updateUserStatus } from "../helpers/statusHelper.js";
import "./ReviewInformation.css";
import "./AddressDate.css";
import onChecked from "../assets/click-Icon.png";
import getPages from "../helpers/userFlowHelper.js";

/*
  AddressDate.js:
  - Path: /address-date
  - Description: This is where the user can set their shipping address and date
*/
const stateCodes = [
  "",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
];
const addrRe = /^[0-9a-zA-Z\s#.;:']+$/;
const cityRe = /^[a-zA-Z\s.;'&/()-]+$/;
const zipRe = /^[0-9]+$/;
const text = "Fill out the shipping information below and select a study date.";

const AddressDate = () => {
  const MIN_SPOTS = 0;
  const START_DATE_PATH = "/start-date";
  const SPOTS_API_PATH = "/spots-left";
  const DATES_API_PATH = "/retrieve-dates";

  const [errorState, setErrorState] = useState(null);
  const [mode, setMode] = useState("INPUT");
  const [popUp, setPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [reviewError, setReviewError] = useState("");
  const [reviewBtnStatus, setReviewBtnStatus] = useState(true);
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [possibleDates, setPossibleDates] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [filledAddress, setFilledAddress] = useState(null);
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [userPage, setUserPage] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const AUTO_LOGOUT_TIME = REFRESH_TOKEN_TIME;

  let credentials = useSelector(getCredentialsState);
  useEffect(() => {
    let today = new Date();
    let yearDate = today.toISOString().substring(0, 7);
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        getAvailableDates(yearDate, accessToken, idToken);
        getPages(accessToken, idToken, { history, setUserPage });
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
    let endpoint = new URL(process.env.REACT_APP_API_BASE_URL + SPOTS_API_PATH);

    fetch(endpoint, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const spotsLeft = data.data;
        if (spotsLeft === MIN_SPOTS) {
          setPopup(true);
        }
      });
  }, [credentials, dispatch, history]);

  const onMonthChange = (e) => {
    const yearDate = e.toISOString().substring(0, 7);
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        getAvailableDates(yearDate, accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  };

  const getAvailableDates = (yearDate, accessToken, idToken) => {
    const endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + DATES_API_PATH
    );
    const headers = {
      Authorization: idToken,
      "Access-Token": accessToken,
      "Year-Month": yearDate,
    };
    fetch(endpoint, { method: "get", headers: headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let unmodifiedDates = data.data;
        let modifiedDates = [];
        for (let i = 0; i < unmodifiedDates.length; i++) {
          let tempDateObject = new Date(unmodifiedDates[i]);
          modifiedDates.push(tempDateObject);
        }
        setPossibleDates(modifiedDates);
      });
  };

  const onSubmitReview = (e) => {
    e.preventDefault();
    setReviewBtnStatus(false);
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        uploadAddressDate(accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  };

  const uploadAddressDate = (accessToken, idToken) => {
    const endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + START_DATE_PATH
    );
    const fullAddress =
      address1 +
      (address2.length !== 0 ? "," + address2 + "," : ",") +
      city +
      "," +
      state +
      " " +
      zipcode;
    const headers = {
      "Access-Token": accessToken,
      Authorization: idToken,
      "Start-Date": moment(startDate).format("yyyy-MM-DD"),
      Name: name,
      Address: fullAddress,
    };
    return fetch(endpoint, {
      method: "POST",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 409) {
          setReviewError(
            "Oops, seems like that date has been taken. Please go back and select a new date."
          );
          setReviewBtnStatus(false);
          const testingNightStatus =
            document.getElementById("testing-night-date");
          testingNightStatus.className = "review-col-r-date-failed";

          setErrorState("Requested date has been taken");
          const err = new Error("Requested date has been taken");
          throw err;
        } else if (!response.ok) {
          setReviewError(
            "Oops, seems like an error occurred while submitting your information."
          );
          setReviewBtnStatus(false);
          const testingNightStatus =
            document.getElementById("testing-night-date");
          testingNightStatus.className = "review-col-r-date-failed";

          setErrorState("An error occurred");
          const err = new Error("An error occurred");
          throw err;
        }
      })
      .then((response) => {
        updateUserStatus(credentials, "address-and-date", () => {
          history.push({
            pathname: "/confirm-information",
            search: "?redirect=false",
          });
        });
      })
      .catch((error) => {
        return;
      });
  };

  const onClickGoBack = () => {
    if (mode === "REVIEW") {
      setReviewBtnStatus(true);
      setReviewError("");
      setErrorState(null);
      setMode("INPUT");
    }
  };

  const onSubmitInput = (e) => {
    e.preventDefault();
    if (name.length === 0) {
      console.log("Name invalid");
      setErrorState("");
      return;
    }
    if (address1.length === 0 || !addrRe.test(address1)) {
      console.log("Address 1 invalid");
      setErrorState("Address 1 invalid");
      return;
    }
    if (address2.length !== 0 && !addrRe.test(address2)) {
      console.log("Address 2 invalid");
      setErrorState("Address 2 invalid");
      return;
    }
    if (city.length === 0 || !cityRe.test(city)) {
      console.log("City invalid");
      setErrorState("City invalid");
      return;
    }
    if (state === "" || state.length !== 2) {
      console.log("State invalid");
      setErrorState("State invalid");
      return;
    }
    if (zipcode.length !== 5 || !zipRe.test(zipcode)) {
      console.log("Zipcode invalid");
      setErrorState("Zipcode invalid");
      return;
    }

    const fullAddress =
      address1.trim() + "," + city.trim() + "," + state + " " + zipcode;
    const endpoint = new URL(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        fullAddress +
        ".json"
    );
    const params = {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
      autocomplete: false,
      country: ["US"],
      limit: 1,
      types: ["address"],
    };
    endpoint.search = new URLSearchParams(params).toString();
    fetch(endpoint, { method: "get" })
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        const features = response["features"][0];
        if (
          features["relevance"] !== 1 ||
          features["properties"]["accuracy"] === "interpolated" ||
          features["properties"]["accuracy"] === "street"
        ) {
          console.log("Address not found");
          // Display popup here
          var filledAddress = {
            address1: address1,
            address2: address2.trim(),
            city: city,
            state: state,
            zipcode: zipcode,
          };
          if (features["properties"]["accuracy"] !== "street") {
            setFilledAddress(filledAddress);
            setSuggestedAddress(features);
            setAddressPopup(true);
          }

          setErrorState("Address not found.");
          return;
        }
        const address = {};
        features.context.map((info) => {
          if (info["id"].includes("postcode")) {
            address["zipcode"] = info["text"];
          } else if (info["id"].includes("place")) {
            address["city"] = info["text"];
          } else if (info["id"].includes("region")) {
            address["state"] = info["short_code"].substring(3);
          }
          return null;
        });
        if (zipcode !== address["zipcode"] || state !== address["state"]) {
          console.log("Address not found");
          setErrorState("Address not found.");
          return;
        }
        setAddress1(features["address"] + " " + features["text"]);
        setCity(address["city"]);

        let date = moment(startDate);
        if (!date.isValid()) {
          console.log("Date invalid");
          setErrorState("Date invalid");
          return;
        }
        setMode("REVIEW");
      })
      .catch((error) => {
        setErrorState("Address not found.");
      });
  };

  const updateAddress = (address) => {
    setAddress1(address.address1);
    setAddress2(address.address2);
    setCity(address.city);
    setState(address.state);
    setZipcode(address.zipcode);

    setErrorState(null);
    setAddressPopup(false);
  };

  if (userPage === true) {
    if (mode === "REVIEW") {
      return (
        <>
          <SpotsPopup trigger={popUp} setTrigger={setPopup} />
          <TimerComponent time={AUTO_LOGOUT_TIME} destination={"/logout"} />
          <PageHeader />
          <div className="main-reviewinfo">
            <UserFlowBar stage="all-done" />
            <div className="inner-main">
              <div className="inner-title"></div>
              <div className="inner-title-2"></div>
              <div className="border-bottom-line"></div>
              <div className="review-main-box">
                <div className="review-row">
                  <div className="review-col-l">Name</div>
                  <div className="review-col-r">{name}</div>
                </div>
                <div className="review-row">
                  <div className="review-col-l">Shipping Address</div>
                  <div className="review-col-r">
                    {address1}

                    {address2.length !== 0 && <br />}
                    {address2}
                    <br />
                    {city + ", " + state + ", " + zipcode}
                  </div>
                </div>
                <div className="review-row">
                  <div className="review-col-l">Testing Night</div>
                  <div id="testing-night-date" className="review-col-r-date">
                    {moment(startDate).format("MM/DD/yyyy")}
                  </div>
                </div>
                <div className="review-row">
                  <div className="review-col-l"></div>
                  <div className="review-col-r">
                    <div className="errorMessage-new">{reviewError}</div>
                  </div>
                </div>
              </div>
              <div className="review-row-button">
                <div className="review-col-l">
                  <button className="review-btn" onClick={onClickGoBack}>
                    Back
                  </button>
                </div>
                <div className="review-col-r-new">
                  <button
                    disabled={!reviewBtnStatus}
                    className="review-btn"
                    onClick={onSubmitReview}
                  >
                    Looks Right!
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PageFooter />
        </>
      );
    } else {
      return (
        <>
          <TimerComponent time={AUTO_LOGOUT_TIME} destination={"/logout"} />
          <SpotsPopup trigger={popUp} setTrigger={setPopup} />
          <AddressPopup
            trigger={addressPopup}
            setTrigger={setAddressPopup}
            filledAddress={filledAddress}
            suggestedAddress={suggestedAddress}
            updateAddress={updateAddress}
          />
          <PageHeader />
          <div className="main-addressdate">
            <UserFlowBar stage="schedule" />
            <div className="form-mian">
              <div className="addressdate-text">{text}</div>
              <form className="form-container">
                <div className="first-box">
                  <div className="form-item">
                    <input
                      id="name"
                      className="form-style"
                      value={name}
                      maxlength="2048"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="address-form-lable">Name</div>
                  </div>
                  <div className="form-item">
                    <input
                      type="text"
                      id="address1"
                      className={
                        errorState ? "form-style error-style" : "form-style"
                      }
                      value={address1}
                      maxLength="46"
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                    <div
                      className={
                        errorState
                          ? "address-form-lable error-text"
                          : "address-form-lable"
                      }
                    >
                      Address Line 1
                    </div>
                  </div>
                  <div className="form-item">
                    <input
                      type="text"
                      className={
                        errorState ? "form-style error-style" : "form-style"
                      }
                      id="address2"
                      value={address2}
                      maxLength="46"
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                    <div
                      className={
                        errorState
                          ? "address-form-lable error-text"
                          : "address-form-lable"
                      }
                    >
                      Address Line 2
                    </div>
                  </div>
                  <div className="form-item">
                    <input
                      type="text"
                      className={
                        errorState ? "form-style error-style" : "form-style"
                      }
                      id="city"
                      value={city}
                      maxLength="50"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <div
                      className={
                        errorState
                          ? "address-form-lable error-text"
                          : "address-form-lable"
                      }
                    >
                      City
                    </div>
                  </div>
                  <div className="form-bottom">
                    <div className="form-select">
                      <div className="form-field">
                        <select
                          className={
                            errorState
                              ? "form-style select error-style"
                              : "form-style select"
                          }
                          id="state"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        >
                          {stateCodes.map((state, i) => (
                            <option key={i} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                        <div
                          className={
                            errorState
                              ? "address-form-lable error-text"
                              : "address-form-lable"
                          }
                        >
                          State
                        </div>
                        <span>
                          <img
                            className="form-checked"
                            src={onChecked}
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-zip">
                      <div className="form-field">
                        <input
                          className={
                            errorState ? "form-style error-style" : "form-style"
                          }
                          id="zipcode"
                          value={zipcode}
                          maxLength="5"
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                        <div
                          className={
                            errorState
                              ? "address-form-lable error-text"
                              : "address-form-lable"
                          }
                        >
                          Zip
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorState && (
                    <div className="errorMessage">{errorState}</div>
                  )}
                </div>
                <div className="line-border"></div>
                <div className="second-box">
                  <div className="form-datepicker">
                    <DatePicker
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                      className="form-style"
                      dateFormat="MM/dd/yyyy"
                      dateFormatCalendar="MMMM"
                      disabledKeyboardNavigation
                      selected={startDate}
                      includeDates={possibleDates}
                      onChange={(date) => setStartDate(date)}
                      onMonthChange={(e) => onMonthChange(e)}
                    />
                    <div className="address-form-lable">Available Dates</div>
                    <span>
                      <img className="form-calender" src={onChecked} alt="" />
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="form-btn-next"
                    disabled={
                      !(
                        name &&
                        address1 &&
                        startDate &&
                        city &&
                        state &&
                        zipcode
                      )
                    }
                    onClick={onSubmitInput}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
          <PageFooter />
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default AddressDate;
