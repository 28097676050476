import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./LandingPageHeader.css";
import LandingLogo from "../assets/landing-logo.png";

const LandingPageHeader = ({
  shadow,
  text,
  onButtonClick,
  landing,
  onLandingbtnClick,
  landingBtnText,
}) => {
  const history = useHistory();
  const onLogoClick = () => {
    history.push({
      pathname: "/",
    });
  };

  let secondBtn = null;
  if (landing) {
    secondBtn = (
      <button className="qualify-btn" onClick={onLandingbtnClick}>
        {landingBtnText}
      </button>
    );
  }
  useEffect(() => {
    if (landing) {
      const headerImg = document.getElementById("header-img");
      headerImg.className = "custom-landing-logo";
      const mainBtn = document.getElementById("main-btn");
      mainBtn.className = "custom-login-btn";
    }
  }, []);
  return (
    <div className={`landing-header ${shadow && "header-shadow"}`}>
      <img
        id="header-img"
        className="landing-logo"
        src={LandingLogo}
        alt="Landing Logo"
        onClick={onLogoClick}
      />
      <button id="main-btn" className="login-btn" onClick={onButtonClick}>
        {text}
      </button>
      {secondBtn}
    </div>
  );
};

export default LandingPageHeader;
