import {
  STORE_CREDENTIALS,
  CLEAR_CREDENTIALS,
  STORE_SURVEY,
} from "./actionTypes";

export const storeCredentials = (content) => ({
  type: STORE_CREDENTIALS,
  payload: { content },
});

export const clearCredentials = () => ({
  type: CLEAR_CREDENTIALS,
});

export const storeSurvey = (survey) => ({
  type: STORE_SURVEY,
  survey: survey,
});
