import { STORE_CREDENTIALS, CLEAR_CREDENTIALS } from "../actionTypes";
import { REFRESH_TOKEN_TIME } from "../../helpers/credentialsHelper";

const REFRESH_TOKEN_EXPIRES_IN = REFRESH_TOKEN_TIME
const MILLISECONDS_PER_SECONDS = 1000;
const EXPIRES_IN_OFFSET = 500;
const localStorage = window.localStorage;

const initialState = {
  isLoggedIn: false,
  credentials: null,
};

export default function credentials(state = initialState, action) {
  switch (action.type) {
    case STORE_CREDENTIALS: {
      const credentials = action.payload.content;
      let timestamp = Date.now();

      const expiresInSeconds = credentials.ExpiresIn;

      credentials.ExpiresIn =
        timestamp + expiresInSeconds * MILLISECONDS_PER_SECONDS - EXPIRES_IN_OFFSET;

      localStorage.setItem("AccessToken", credentials.AccessToken);
      localStorage.setItem("IdToken", credentials.IdToken);
      if (credentials.RefreshToken !== undefined) {
        localStorage.setItem("RefreshToken", credentials.RefreshToken);
      }
      localStorage.setItem(
        "ExpiresIn",
        timestamp +
          expiresInSeconds * MILLISECONDS_PER_SECONDS -
          EXPIRES_IN_OFFSET
      );
      localStorage.setItem(
        "RefreshTokenExpiresIn",
        timestamp +
          REFRESH_TOKEN_EXPIRES_IN -
          EXPIRES_IN_OFFSET
      );
      localStorage.setItem("isLoggedIn", true);

      return {
        ...state,
        isLoggedIn: true,
        credentials: {
          ...credentials,
          ExpiresIn: timestamp + expiresInSeconds * MILLISECONDS_PER_SECONDS,
        },
      };
    }
    case CLEAR_CREDENTIALS: {
      localStorage.clear();
      return initialState;
    }
    default: {
      const expiresIn = parseInt(localStorage.getItem("RefreshTokenExpiresIn"));

      if (expiresIn < Date.now()) {
        localStorage.clear();
        return initialState;
      }

      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

      if (isLoggedIn) {
        const accessToken = localStorage.getItem("AccessToken");
        const idToken = localStorage.getItem("IdToken");
        const refreshToken = localStorage.getItem("RefreshToken");
        const expiresIn = parseInt(localStorage.getItem("ExpiresIn"));

        const credentials = {
          AccessToken: accessToken,
          IdToken: idToken,
          RefreshToken: refreshToken,
          ExpiresIn: expiresIn,
        };
        return {
          isLoggedIn: true,
          credentials: credentials,
        };
      } 

      return state;
    }
  }
}
