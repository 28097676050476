/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.
NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC
employees, managers or contractors who have executed Confidentiality and
Non-disclosure agreements explicitly covering such access.
The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React from "react";
import noSpotsIcon from "../assets/no-spots-icon.png";
import "./SpotsPopup.css";

/*
  SpotsPopup.js:
  - Path: None
  - Description: This is the component that creates a popup when there are no spots left
*/

const SpotsPopup = ({ trigger, setTrigger }) => {
  const closePopup = () => {
    setTrigger(false);
  };
  if (trigger) {
    return (
      <div className="spots-popup">
        <div className="spots-popup-inner">
          <button className="x-btn" onClick={closePopup} />
          <img className="spots-popup-icon" alt="no-spots-icon" src={noSpotsIcon} />
          <p>
            Sorry, we ran out of spots in our sleep study!
            Please see your primary care physician and have
            them assess you for obstructive sleep apnea. <br /> <br />
            Alternatively you may see a primary care physician virtually
            by logging into your Anthem benefits through Sydney app and
            initiating a virtual visit with a provider or calling the
            24/7 nurse line number on the back of your card.
          </p>
          <button className="spots-close-btn" onClick={closePopup}>
            Close
          </button>
        </div>
      </div>
    );
  } else return "";
};

export default SpotsPopup;
