/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.
NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC
employees, managers or contractors who have executed Confidentiality and
Non-disclosure agreements explicitly covering such access.
The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState } from "react";

import CloseIcon from "../assets/icon_close.png";
import emailIcon from "../assets/email.png";
import emailError from "../assets/ErrorEmail.png";
import inputSuccess from "../assets/input-success.png";
import inputFail from "../assets/input-fail.png";
import "./EmailShare.css";
/*
  EmailShare.js:
  - Path: None
  - Description: This is the component that creates a popup for sharing pdf report
*/

const EmailShare = ({ date, share, setDate }) => {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [emailInput, setEmailInput] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const closePopup = () => {
    setEmail("");
    setInputErrorMessage("");
    setDate(null);
  };
  const submit = async () => {
    try {
      await share(date, email);
      console.log("shared");
      closePopup();
      setEmail("");
    } catch (error) {
      setInputErrorMessage("An error occurred");
      const emailBox = document.getElementById("email-box");
      const inputStatus = document.getElementById("email-input-status");
      const inputIcon = document.getElementById("email-share-icon");
      setEmailInput(false);
      emailBox.className = "email-input-failed";
      inputStatus.src = inputFail;
      inputIcon.src = emailError;
      inputStatus.alt = "input-status-failed";
    }
  };

  const onEmailChange = (e) => {
    e.preventDefault();
    const emailBox = document.getElementById("email-box");
    const inputStatus = document.getElementById("email-input-status");
    const inputIcon = document.getElementById("email-share-icon");
    inputStatus.hidden = false;
    if (EMAIL_REGEX.test(emailBox.value)) {
      setEmailInput(true);
      emailBox.className = "email-input";
      inputStatus.src = inputSuccess;
      inputIcon.src = emailIcon;
      inputStatus.alt = "email-input-status-success";
      setInputErrorMessage("");
    } else {
      setEmailInput(false);
      emailBox.className = "email-input-failed";
      inputStatus.src = inputFail;
      inputIcon.src = emailError;
      inputStatus.alt = "email-input-status-failed";
      setInputErrorMessage("Invalid email format.");
    }
  };

  if (date) {
    return (
      <div className="popup-email">
        <div className="popup-inner-email">
          <img
            className="email-close"
            src={CloseIcon}
            onClick={closePopup}
            alt="close"
          ></img>
          <div className="share-title">Share Your Report</div>
          <div className="share-details">
            Give your health care provider insight into your sleep.
          </div>

          <div className="email-input-row">
            <input
              id="email-box"
              className="email-input"
              type="text"
              value={email}
              placeholder="Enter email address "
              onChange={(e) => setEmail(e.target.value)}
              onInput={onEmailChange}
            ></input>
            <span className="email-img">
              <img src={emailIcon} id="email-share-icon" alt="Password Icon" />
            </span>
            <span className="input-validate">
              <img
                alt="email-input-status"
                id="email-input-status"
                hidden={true}
              />
            </span>
          </div>
          <div className="email-wrong-input-message">{inputErrorMessage}</div>
          <button
            className="link-btn-email"
            onClick={submit}
            disabled={!emailInput}
          >
            Share
          </button>
        </div>
      </div>
    );
  } else return "";
};

export default EmailShare;
