import { STORE_SURVEY } from "../actionTypes";

const localStorage = window.localStorage;

const initialState = {
  survey: localStorage.getItem("Survey"),
};

export default function survey(state = initialState, action) {
  switch (action.type) {
    case STORE_SURVEY: {
      const survey = action.survey;
      localStorage.setItem("Survey", survey);

      return {
        ...state,
        survey: survey,
      };
    }
    default: {
      return state;
    }
  }
}
