import "node-fetch";
import "regenerator-runtime/runtime";

const LOGIN_API_PATH = "/login"

export const REFRESH_TOKEN_TIME = 15 * 60 * 1000; // 15 minutes

export const refreshCredentials = async (token) => {
  const endpoint = new URL(process.env.REACT_APP_API_BASE_URL + LOGIN_API_PATH);
  const headers = {
    "Refresh-Token": token
  }
  let response = await fetch(endpoint, {method: "post", headers: headers});
  let data = await response.json();

  if (response.status === 200) {
    return data;
  } else {
    return;
  }
};

export const refreshAndCall = (credentials, callback) => {
  if (credentials.credentials.ExpiresIn < Date.now()) {
    refreshCredentials(credentials.credentials.RefreshToken)
      .then((data) => {
        callback(
          data.AuthenticationResult.AccessToken,
          {
            ...data.AuthenticationResult,
            RefreshToken: credentials.credentials.RefreshToken
          }
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  } else {
    callback(credentials.credentials.AccessToken, null);
    return;
  }
};

export const refreshAndCallWithIdToken = (credentials, callback) => {
  if (credentials.credentials.ExpiresIn < Date.now()) {
    refreshCredentials(credentials.credentials.RefreshToken)
      .then((data) => {
        callback(
          data.AuthenticationResult.AccessToken,
          data.AuthenticationResult.IdToken,
          {
            ...data.AuthenticationResult,
            RefreshToken: credentials.credentials.RefreshToken
          }
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  } else {
    callback(
      credentials.credentials.AccessToken,
      credentials.credentials.IdToken,
      null
    );
    return;
  }
};
