/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "node-fetch";

import { useSelector, useDispatch } from "react-redux";
import { clearCredentials, storeCredentials } from "../redux/actions";
import { getCredentialsState } from "../redux/selectors";
import { refreshAndCallWithIdToken } from "../helpers/credentialsHelper.js";

/*
  TimerComponent.js:
  - Path: None
  - Description: This is the component that can move to another page after a specific time in millisecond
*/

const TimerComponent = ({ time, destination }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const HTTP_STATUS_OK = 200;
  const LOGOUT_API_PATH = "/logout";
  let credentials = useSelector(getCredentialsState);

  useEffect(() => {
    let timer;
    const onResetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(onTimeOut, time);
    };
    window.onload = onResetTimer;
    document.onmousemove = onResetTimer;
    document.onkeypress = onResetTimer;
    document.onscroll = onResetTimer;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const logout = async (accessToken, idToken) => {
    let endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + LOGOUT_API_PATH
    );
    let headers = {
      Authorization: idToken,
      "Access-Token": accessToken,
    };
    let response = await fetch(endpoint, { method: "post", headers: headers });
    // console.log(response.status);
    if (response.status !== HTTP_STATUS_OK) {
      console.log("Endpoint Failure");
    }
    dispatch(clearCredentials());
  };

  const onTimeOut = () => {
    if (destination === "/logout") {
      const currentTime = Date.now();
      const refreshTokenExpiresIn = parseInt(
        localStorage.getItem("RefreshTokenExpiresIn")
      );
      if (refreshTokenExpiresIn > currentTime) {
        // another tab was more recently active
        const timeDelta = refreshTokenExpiresIn - currentTime;
        const newTimer = setTimeout(onTimeOut, timeDelta);

        return () => {
          clearTimeout(newTimer);
        };
      } else {
        refreshAndCallWithIdToken(
          credentials,
          function (accessToken, idToken, credentials) {
            logout(accessToken, idToken);
            if (credentials !== null) {
              dispatch(storeCredentials(credentials));
            }
          }
        );
        console.log("Redirecting to" + destination);
        history.push({
          pathname: destination,
        });
      }
      return null;
    }
    console.log("Redirecting to" + destination);
    history.push({
      pathname: destination,
    });
  };
  return null;
};

export default TimerComponent;
