/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "node-fetch";
import BasicPageHeader from "../components/PageHeader.js";
import PageHeader from "../components/LandingPageHeader.js";
import PageFooter from "../components/PageFooter.js";
import { useDispatch } from "react-redux";
import { storeCredentials } from "../redux/actions";
import email from "../assets/email.png";
import emailError from "../assets/ErrorEmail.png";
import passwordIcon from "../assets/password.png";
import passwordError from "../assets/ErrorPassword.png";
import inputFail from "../assets/input-fail.png";
import phoneVerifyImg from "../assets/phone-verify-img.png";
import "./Login.css";
import { statusToPageMap } from "../helpers/statusHelper.js";

/*
  Login.js:
  - Path: /login
  - Description: This is the page for logging in ANNE Sleep Hub users after they sign up
*/

const Login = () => {
  // const ASSOCIATE_API_PATH = "/associate-mfa";
  // const VERIFY_API_PATH = "/verify-mfa";

  const [challengeName, setChallengeName] = useState("LOGIN");
  const [errorState, setErrorState] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  // const [authString, setAuthString] = useState("");
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [session, setSession] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usersub, setUsersub] = useState("");
  const [input1, setInput1] = useState(false);
  const [input2, setInput2] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [verifyBtn, setBtn] = useState(false);
  const history = useHistory();
  let usernameValue = "";
  let passwordValue = "";
  let btnRef = useRef();

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const LOGIN_API_PATH = "/login";
  const STATUS_API_PATH = "/status";
  const SMS_MFA_API_PATH = "/sms-mfa";
  const RESEND_API_PATH = "/resend-confirmation-code";

  const dispatch = useDispatch();

  const onLoginClick = async (e) => {
    //disabling the button until the call is finished
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", true);
    }
    e.preventDefault();
    if (input1 && input2) {
      console.log("Correct Inputs");
      const usernameInput = document.getElementById("username");
      const passwordInput = document.getElementById("password");

      let headerUsername = "";
      let headerPassword = "";

      if (usernameInput === null && passwordInput === null) {
        headerUsername = username;
        headerPassword = password;
      } else {
        usernameValue = usernameInput.value;
        passwordValue = passwordInput.value;
        setUsername(usernameValue);
        setPassword(passwordValue);
        headerUsername = usernameValue;
        headerPassword = passwordValue;
      }

      const loginEndpoint = new URL(BASE_URL + LOGIN_API_PATH);
      const loginHeaders = {
        Username: headerUsername,
        Password: headerPassword,
      };

      try {
        let response = await fetch(loginEndpoint, {
          method: "post",
          headers: loginHeaders,
        });
        let data = await response.json();
        switch (response.status) {
          case 200: // good, move on to sms mfa
            if (data.ChallengeName === "SMS_MFA") {
              usernameValue = "";
              passwordValue = "";
              setUsersub(data.ChallengeParameters.USER_ID_FOR_SRP);
              setSession(data.Session);
              setChallengeName("SMS_MFA");
              setErrorState(false);
            } else {
              // Unsupported MFA type, or no MFA
              setErrorState(true);
            }
            break;
          case 400: // bad request, no username or password
            onInputError();
            break;
          case 401: // Unauthoriized, user not found or not authorized
            onInputError();
            break;
          case 403: // Forbidden, user not confirmed. Resend activation code
            const resendCodeEndpoint = new URL(BASE_URL + RESEND_API_PATH);
            const resendCodeHeaders = {
              Username: usernameValue,
            };
            try {
              let data = await fetch(resendCodeEndpoint, {
                method: "post",
                headers: resendCodeHeaders,
              });
              let { response } = await data.json();
              let destination = response["CodeDeliveryDetails"]["Destination"];
              history.push({
                pathname: "/confirmation-code",
                username: usernameValue,
                password: passwordValue,
                phonenumber: destination,
              });
            } catch (error) {
              onInputError();
            }
            break;
          case 500: // Unknown error
            onInputError();
            setInputErrorMessage(
              "Server Error. Please try again in a few minutes."
            );
            break;
          default:
            onInputError();
        }
      } catch (error) {
        onInputError();
        setInputErrorMessage(
          "Oops! Something went wrong with the server. If you are using a VPN, please disable it. If the problem persists, try again in a few minutes."
        );
      }
    } else {
      console.log("Some Inputs are Incorrect or Missing");
    }
  };

  const onInputError = () => {
    setInputErrorMessage("Wrong email or password. Please try again.");
    const inputStatus1 = document.getElementById("email-status");
    const inputIcon1 = document.getElementById("email-icon");
    const box1 = document.getElementById("username");
    const inputStatus2 = document.getElementById("password-status");
    const inputIcon2 = document.getElementById("password-icon");
    const box2 = document.getElementById("password");

    setInput1(false);
    box1.className = "login-input-failed";
    inputStatus1.hidden = false;
    inputStatus1.src = inputFail;
    inputIcon1.src = emailError;
    inputStatus1.alt = "input-status-1-failed";

    setInput2(false);
    box2.className = "login-input-failed";
    inputStatus2.hidden = false;
    inputStatus2.src = inputFail;
    inputIcon2.src = passwordError;
    inputStatus2.alt = "input-status-2-failed";
  };

  const onSmsMfaCodeClick = async (e) => {
    e.preventDefault();
    setBtn(false);
    const mfaVal = code.join("");
    if (mfaVal.length !== 6) {
      setCode(["", "", "", "", "", ""]);
      setErrorMessage("Invalid code. Please try again or resend the code.");
      setErrorState(true);
      return;
    }
    const smsMfaEndpoint = new URL(BASE_URL + SMS_MFA_API_PATH);
    const smsMfaHeaders = {
      Username: usersub,
      Code: mfaVal,
      Session: session,
    };

    try {
      let response = await fetch(smsMfaEndpoint, {
        method: "post",
        headers: smsMfaHeaders,
      });
      let data = await response.json();
      switch (response.status) {
        case 200:
          const authRestult = data.AuthenticationResult;
          dispatch(storeCredentials(authRestult));
          const statusEndpoint = new URL(BASE_URL + STATUS_API_PATH);
          const statusHeaders = {
            Authorization: data.AuthenticationResult.IdToken,
            "Access-Token": data.AuthenticationResult.AccessToken,
          };
          try {
            response = await fetch(statusEndpoint, {
              method: "get",
              headers: statusHeaders,
            });
            data = await response.json();
            switch (response.status) {
              case 200:
                history.push({
                  pathname: statusToPageMap[data.message],
                });
                break;
              default:
                onSmsMfaCodeError();
            }
          } catch (error) {
            onSmsMfaCodeError();
          }
          break;
        default:
          onSmsMfaCodeError();
      }
    } catch (error) {
      onSmsMfaCodeError();
    }
  };

  const onSmsMfaCodeError = () => {
    setCode(["", "", "", "", "", ""]);
    setErrorMessage("Invalid code. Please try again or resend the code.");
    setErrorState(true);
  };

  const onClickForgotPassword = (e) => {
    e.preventDefault();
    console.log("Redirecting to reset-password");
    history.push({
      pathname: "/reset-password",
    });
  };

  const onEmailChange = (e) => {
    e.preventDefault();
    const username = document.getElementById("username");
    const inputStatus = document.getElementById("email-status");
    const inputIcon = document.getElementById("email-icon");
    inputStatus.hidden = true;
    setInput1(true);
    setInputErrorMessage("");
    username.className = "login-input";
    inputIcon.src = email;
    inputStatus.alt = "input-status-1-success";
    if (username.value === null || username.value === "") {
      setInput1(false);
    }

    const password = document.getElementById("password");
    const inputStatus2 = document.getElementById("password-status");
    const inputIcon2 = document.getElementById("password-icon");
    if (password.value !== null && password.value !== "") {
      inputStatus2.hidden = true;
      setInput2(true);
      password.className = "login-input";
      inputIcon2.src = passwordIcon;
      inputStatus2.alt = "input-status-2-success";
    }
  };

  const onPasswordChange = (e) => {
    e.preventDefault();
    const password = document.getElementById("password");
    const inputStatus = document.getElementById("password-status");
    const inputIcon = document.getElementById("password-icon");
    inputStatus.hidden = true;
    setInput2(true);
    setInputErrorMessage("");
    password.className = "login-input";
    inputIcon.src = passwordIcon;
    inputStatus.alt = "input-status-2-success";
    if (password.value === null || password.value === "") {
      setInput2(false);
    }

    const username = document.getElementById("username");
    const inputStatus2 = document.getElementById("email-status");
    const inputIcon2 = document.getElementById("email-icon");
    if (username.value !== null && username.value !== "") {
      inputStatus2.hidden = true;
      setInput1(true);
      username.className = "login-input";
      inputIcon2.src = email;
      inputStatus2.alt = "input-status-1-success";
    }
  };

  const onSignup = (e) => {
    e.preventDefault();
    console.log("Redirecting to signup");
    history.push({
      pathname: "/signup",
    });
  };

  const onCodeEnter = (e, i) => {
    if (e.target.value.length > 1) {
      return;
    }
    const changed = code.map((c, j) => (i === j ? e.target.value : c));
    setCode(changed);
    if (
      changed[0] !== "" &&
      changed[0] !== " " &&
      changed[1] !== "" &&
      changed[1] !== " " &&
      changed[2] !== "" &&
      changed[2] !== " " &&
      changed[3] !== "" &&
      changed[3] !== " " &&
      changed[4] !== "" &&
      changed[4] !== " " &&
      changed[5] !== "" &&
      changed[5] !== " "
    ) {
      setBtn(true);
    } else {
      setBtn(false);
    }
    if (
      e.target.value !== "" &&
      e.target.nextElementSibling &&
      e.target.nextElementSibling.nodeName === "INPUT"
    ) {
      e.target.nextElementSibling.focus();
    }
  };

  const onCodeBack = (e) => {
    if (
      e.target.value === "" &&
      e.keyCode === 8 &&
      e.target.previousElementSibling &&
      e.target.previousElementSibling.nodeName === "INPUT"
    ) {
      e.target.previousElementSibling.focus();
    }
  };

  if (challengeName === "LOGIN") {
    return (
      <>
        <PageHeader text="Sign up" onButtonClick={onSignup} />
        <div className="login-main">
          <div className="page-login">
            <div className="login-form">
              <form onSubmit={onLoginClick}>
                <h2>
                  Welcome to
                  <br></br>
                  ANNE™ Sleep Hub
                </h2>
                <div className="login-input-row">
                  <input
                    className="login-input"
                    type="text"
                    id="username"
                    placeholder="Enter email address"
                    onInput={onEmailChange}
                  ></input>
                  <span className="input-img-login">
                    <img src={email} id="email-icon" alt="Email Icon" />
                  </span>
                  <span className="input-validate">
                    <img alt="email-status" id="email-status" hidden={true} />
                  </span>
                </div>

                <div id="second-box" className="login-input-row">
                  <input
                    className="login-input"
                    type="password"
                    id="password"
                    placeholder="Enter password"
                    onInput={onPasswordChange}
                  ></input>
                  <span className="input-img-login">
                    <img
                      src={passwordIcon}
                      id="password-icon"
                      alt="Password Icon"
                    />
                  </span>
                  <span className="input-validate">
                    <img
                      alt="password-status"
                      id="password-status"
                      hidden={true}
                    />
                  </span>
                </div>
                <div className="login-wrong-input-message">
                  {inputErrorMessage}
                </div>
                <button
                  ref={btnRef}
                  className="form-login-btn"
                  type="submit"
                  disabled={!input1 || !input2}
                >
                  LOG IN
                </button>
              </form>
              <span
                className="login-forget"
                onClick={(e) => onClickForgotPassword(e)}
              >
                Forgot Password?
              </span>
            </div>
          </div>
        </div>
        <div className="login-footer">
          <PageFooter footerIndex />
        </div>
      </>
    );
  } else if (challengeName === "SMS_MFA") {
    return (
      <>
        <BasicPageHeader />
        <div className="main-sms-mfa">
          <div className="horizontal-line" />
          <div className="sms-mfa-container">
            <img
              className="sms-mfa-image"
              alt="phone-verify"
              src={phoneVerifyImg}
            />
            <form
              data-testid="code_form"
              className="sms-mfa-form"
              onSubmit={onSmsMfaCodeClick}
              autoComplete="off"
            >
              <h1>Verify It's You</h1>
              <h3>
                We sent a 6-digit code to your phone. <br /> Please enter it
                below.
              </h3>
              <div
                className={
                  errorState ? "sms-mfa-code error-message" : "sms-mfa-code"
                }
              >
                <input
                  data-testid="code_1"
                  id="verification_code_1"
                  value={code[0]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 0)}
                />
                <input
                  data-testid="code_2"
                  id="verification_code_2"
                  value={code[1]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 1)}
                />
                <input
                  data-testid="code_3"
                  id="verification_code_3"
                  value={code[2]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 2)}
                />
                <input
                  data-testid="code_4"
                  id="verification_code_4"
                  value={code[3]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 3)}
                />
                <input
                  data-testid="code_5"
                  id="verification_code_5"
                  value={code[4]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 4)}
                />
                <input
                  data-testid="code_6"
                  id="verification_code_6"
                  value={code[5]}
                  inputMode="tel"
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 5)}
                />
                <p>{errorState && <div>{errorMessage}</div>}</p>
                <button
                  disabled={!verifyBtn}
                  className="sms-mfa-verify-btn"
                  type="submit"
                >
                  VERIFY
                </button>
                <button
                  data-testid="resend_btn"
                  className="sms-mfa-resend-btn"
                  onClick={onLoginClick}
                >
                  Resend the code
                </button>
              </div>
            </form>
          </div>
          <div className="horizontal-line" />
        </div>
        <div className="sms-mfa-footer">
          <PageFooter />
        </div>
      </>
    );
  } else {
    return (
      <div className="page-wrapper">
        <PageHeader text="Sign up" onButtonClick={onSignup} />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>Unexpected error while logging in. Please try again</div>
        <form onSubmit={onLoginClick}>
          <label>Username: </label>
          <input id="username"></input>
          <br></br>
          <label>Password: </label>
          <input id="password" type="password"></input>
          <br></br>
          <input type="submit" value="Login" />
        </form>
        <PageFooter />
      </div>
    );
  }
};

export default Login;
