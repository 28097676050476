/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import PasswordChanged from "../components/PasswordChanged.js";
import "./ChangePassword.css";
import { useSelector, useDispatch } from "react-redux";
import { getCredentialsState } from "../redux/selectors";
import {
  refreshAndCallWithIdToken,
  REFRESH_TOKEN_TIME,
} from "../helpers/credentialsHelper";
import { storeCredentials } from "../redux/actions.js";
import TimerComponent from "../components/TimerComponent.js";
import Password from "../assets/password.png";
import passwordError from "../assets/ErrorPassword.png";
import PasswordChangedImage from "../assets/password-change.png";
import UserFlow from "../components/UserFlow.js";
import inputSuccess from "../assets/input-success.png";
import inputSuccess2 from "../assets/success-input-white.png";
import inputFail from "../assets/input-fail.png";
import inputFail2 from "../assets/failed-input-white.png";

import Tooltip from "@mui/material/Tooltip";

/*
  ChangePassword.js:
  - Path: /change-password
  - Description: This is the page for changing password with access token
*/

const ChangePassword = () => {
  const [mode, setMode] = useState("REQUEST");
  const [errorState, setErrorState] = useState("");
  const [errorState2, setErrorState2] = useState("");
  const [errorState3, setErrorState3] = useState("");
  const [errorStateMain, setErrorStateMain] = useState("");
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [changePasswordButtonEnabled, setChangePasswordButtonEnabled] =
    useState(false);

  const [tooltip, setTooltip] = useState(false);
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [condition4, setCondition4] = useState(false);
  const [condition5, setCondition5] = useState(false);

  const AUTO_LOGOUT_TIME = REFRESH_TOKEN_TIME;

  const history = useHistory();
  const dispatch = useDispatch();
  let credentials = useSelector(getCredentialsState);
  const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const PASSWORD_LENGTH_REGEX = /^.{8,}$/;
  const PASSWORD_UPPER_CASE_REGEX = /^(?=.*[A-Z])/;
  const PASSWORD_LOWER_CASE_REGEX = /^(?=.*[a-z])/;
  const PASSWORD_SPECIAL_CHAR_REGEX = /^(?=.*[@$!%*#?&])/;
  const PASSWORD_ONE_NUMBER_REGEX = /\d/;
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const CHANGE_PASSWORD_PATH = "/change-password";

  const onSubmit = (e) => {
    e.preventDefault();
    setChangePasswordButtonEnabled(false);
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        changePassword(accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  };

  const changePassword = async (accessToken, idToken) => {
    const passwordOld = document.getElementById("oldPassword").value;
    const passwordNew = document.getElementById("newPassword").value;
    const passwordConfirm = document.getElementById("confirmPassword").value;
    if (passwordNew.length !== 0 && passwordNew === passwordConfirm) {
      if (passwordOld.length !== 0) {
        const changePasswordEndpoint = new URL(BASE_URL + CHANGE_PASSWORD_PATH);
        const changePasswordHeader = {
          "Access-Token": accessToken,
          Authorization: idToken,
          Password: passwordOld,
          "New-Password": passwordNew,
        };

        try {
          let response = await fetch(changePasswordEndpoint, {
            method: "post",
            headers: changePasswordHeader,
          });
          let data = await response.json();
          switch (response.status) {
            case 200:
              setMode("SUCCESS");
              break;
            case 409:
              const password = document.getElementById("oldPassword");
              const inputStatus = document.getElementById(
                "old-password-status"
              );
              const inputIcon = document.getElementById("old-icon");
              setOldPassword(false);
              password.className = "change-input-field-failed";
              inputStatus.src = inputFail;
              inputIcon.src = passwordError;
              setChangePasswordButtonEnabled(false);
              setErrorState("Incorrect old password. Please try again.");
              break;
            default:
              setErrorStateMain(
                "Server error. Please try again in a few minutes."
              );
          }
          if (response.status === 200) {
            setMode("SUCCESS");
          }
        } catch (error) {}
      } else {
        console.log("No old password");
        setErrorState("");
      }
    } else {
      console.log("Password mismatched");
      setErrorState3("Password must match the previous entry.");
    }
  };

  const onOldPasswordChange = (e) => {
    e.preventDefault();
    const password = document.getElementById("oldPassword");
    const inputStatus = document.getElementById("old-password-status");
    const inputIcon = document.getElementById("old-icon");
    inputStatus.hidden = false;
    setErrorState("");
    setErrorStateMain("");
    if (PASSWORD_REGEX.test(password.value)) {
      setOldPassword(true);
      password.className = "change-input-field";
      inputStatus.src = inputSuccess;
      inputIcon.src = Password;
      setChangePasswordButtonEnabled(newPassword && confirmPassword);
    } else {
      setOldPassword(false);
      password.className = "change-input-field-failed";
      inputStatus.src = inputFail;
      inputIcon.src = passwordError;
      setChangePasswordButtonEnabled(false);
    }
  };

  const onNewPasswordChange = (e) => {
    e.preventDefault();
    const passwordOld = document.getElementById("oldPassword");
    const passwordNew = document.getElementById("newPassword");
    let inputIcon = document.getElementById("new-icon");
    let inputStatus = document.getElementById("new-password-status");

    inputStatus.hidden = false;
    setErrorStateMain("");

    setCondition1(PASSWORD_LENGTH_REGEX.test(passwordNew.value));
    setCondition2(PASSWORD_UPPER_CASE_REGEX.test(passwordNew.value));
    setCondition3(PASSWORD_LOWER_CASE_REGEX.test(passwordNew.value));
    setCondition4(PASSWORD_SPECIAL_CHAR_REGEX.test(passwordNew.value));
    setCondition5(PASSWORD_ONE_NUMBER_REGEX.test(passwordNew.value));

    if (
      PASSWORD_REGEX.test(passwordNew.value) &&
      passwordOld.value !== passwordNew.value
    ) {
      setErrorState2("");
      setNewPassword(true);
      passwordNew.className = "change-input-field";
      inputStatus.src = inputSuccess;
      inputIcon.src = Password;
      setChangePasswordButtonEnabled(oldPassword && confirmPassword);
    } else {
      setErrorState2("Password must meet the criteria.");
      setNewPassword(false);
      passwordNew.className = "change-input-field-failed";
      inputStatus.src = inputFail;
      inputIcon.src = passwordError;
      setChangePasswordButtonEnabled(false);
    }

    const passwordConfirm = document.getElementById("confirmPassword");
    inputIcon = document.getElementById("confirm-icon");
    inputStatus = document.getElementById("confirm-password-status");

    if (inputStatus.hidden === false) {
      if (passwordNew.value === passwordConfirm.value) {
        setErrorState3("");
        setConfirmPassword(true);
        passwordConfirm.className = "change-input-field";
        inputStatus.src = inputSuccess;
        inputIcon.src = Password;
        setChangePasswordButtonEnabled(oldPassword && newPassword);
      } else {
        setErrorState3("Password must match the previous entry.");
        setConfirmPassword(false);
        passwordConfirm.className = "change-input-field-failed";
        inputStatus.src = inputFail;
        inputIcon.src = passwordError;
        setChangePasswordButtonEnabled(false);
      }
    }
  };

  const onConfirmPasswordChange = (e) => {
    e.preventDefault();
    const passwordNew = document.getElementById("newPassword");
    const passwordConfirm = document.getElementById("confirmPassword");
    const inputStatus = document.getElementById("confirm-password-status");
    const inputIcon = document.getElementById("confirm-icon");
    inputStatus.hidden = false;
    setErrorStateMain("");
    if (passwordNew.value === passwordConfirm.value) {
      setErrorState3("");
      setConfirmPassword(true);
      passwordConfirm.className = "change-input-field";
      inputStatus.src = inputSuccess;
      inputIcon.src = Password;
      setChangePasswordButtonEnabled(oldPassword && newPassword);
    } else {
      setErrorState3("Password must match the previous entry.");
      setConfirmPassword(false);
      passwordConfirm.className = "change-input-field-failed";
      inputStatus.src = inputFail;
      inputIcon.src = passwordError;
      setChangePasswordButtonEnabled(false);
    }
  };

  const onClickGoBack = () => {
    console.log("redirecting to dashboard page");
    history.push({
      pathname: "/",
    });
  };

  const onToReset = () => {
    console.log("redirecting to reset-password page");
    history.push({
      pathname: "/reset-password",
    });
  };

  const handleTooltipClose = (event) => {
    setTooltip(false);
  };

  const handleTooltipOpen = (event) => {
    setTooltip(true);
  };

  if (mode === "SUCCESS") {
    return (
      <>
        <UserFlow></UserFlow>
        <TimerComponent time={AUTO_LOGOUT_TIME} destination={"/logout"} />
        <PageHeader />
        <PasswordChanged onClick={onClickGoBack} buttonText="GO TO DASHBOARD" />
        <PageFooter />
      </>
    );
  } else {
    return (
      <>
        <UserFlow></UserFlow>
        <TimerComponent time={AUTO_LOGOUT_TIME} destination={"/logout"} />
        <PageHeader />
        <div className="password-main">
          <div class="horizontal-line"></div>
          <div className="password-container">
            <img
              className="password-img"
              src={PasswordChangedImage}
              alt="Password Changed"
            />
            <form className="password-form" onSubmit={onSubmit}>
              <h1 className="changed-password">Change Password</h1>

              <div className="change-input-row">
                <input
                  className="change-input-field"
                  id="oldPassword"
                  placeholder="Enter old password"
                  type="password"
                  onInput={onOldPasswordChange}
                ></input>

                <span className="change-input-img">
                  <img src={Password} id="old-icon" alt="Password Icon" />
                </span>
                <span className="change-input-validate">
                  <img id="old-password-status" hidden={true} alt="" />
                </span>
              </div>

              <div className="errorMessage-pass">{errorState}</div>

              <div className="change-input-row">
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="top-start"
                  arrow
                  title={
                    <>
                      <div className="change-condition-row">
                        <img
                          id="condition-img-1"
                          className="change-condition-img-holder"
                          alt=""
                          src={condition1 ? inputSuccess2 : inputFail2}
                        />
                        <span className="change-condition-text">
                          At least 8 characters
                        </span>
                      </div>

                      <div className="change-condition-row">
                        <img
                          id="condition-img-2"
                          className="change-condition-img-holder"
                          alt=""
                          src={condition2 ? inputSuccess2 : inputFail2}
                        />
                        <span className="change-condition-text">
                          At least one upper case letter
                        </span>
                      </div>

                      <div className="change-condition-row">
                        <img
                          id="condition-img-3"
                          className="change-condition-img-holder"
                          alt=""
                          src={condition3 ? inputSuccess2 : inputFail2}
                        />
                        <span className="change-condition-text">
                          At least one lower case letter
                        </span>
                      </div>

                      <div className="change-condition-row">
                        <img
                          id="condition-img-5"
                          className="change-condition-img-holder"
                          alt=""
                          src={condition5 ? inputSuccess2 : inputFail2}
                        />
                        <span className="change-condition-text">
                          At least one number
                        </span>
                      </div>

                      <div className="change-condition-row">
                        <img
                          id="condition-img-4"
                          className="change-condition-img-holder"
                          alt=""
                          src={condition4 ? inputSuccess2 : inputFail2}
                        />
                        <span className="change-condition-text">
                          At least one special character
                        </span>
                      </div>
                    </>
                  }
                >
                  <input
                    data-tip
                    data-for="pass1-tip"
                    data-event="focusin"
                    className="change-input-field"
                    id="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    onInput={onNewPasswordChange}
                    onFocus={handleTooltipOpen}
                    onBlur={handleTooltipClose}
                  ></input>
                </Tooltip>
                <span className="change-input-img">
                  <img src={Password} id="new-icon" alt="Password Icon" />
                </span>
                <span className="change-input-validate">
                  <img id="new-password-status" hidden={true} alt="" />
                </span>
              </div>

              <div className="errorMessage-pass">{errorState2}</div>

              <div className="change-input-row-last">
                <input
                  className="change-input-field"
                  id="confirmPassword"
                  type="password"
                  placeholder="Re-enter new password"
                  onInput={onConfirmPasswordChange}
                ></input>
                <span className="change-input-img">
                  <img src={Password} id="confirm-icon" alt="Password Icon" />
                </span>
                <span className="change-input-validate">
                  <img id="confirm-password-status" hidden={true} alt="" />
                </span>
              </div>

              <div className="errorMessage-pass">{errorState3}</div>
              <div className="errorMessage-pass-main">{errorStateMain}</div>

              <button
                type="submit"
                disabled={!changePasswordButtonEnabled}
                className="changed-btn"
              >
                CONFIRM
              </button>
              <button onClick={onToReset} className="to-reset-btn">
                Forgot Password?
              </button>
            </form>
          </div>
          <div class="horizontal-line"></div>
        </div>
        <PageFooter />
      </>
    );
  }
};

export default ChangePassword;
