/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import "./ResetPassword.css";
import PasswordChanged from "../components/PasswordChanged.js";
import PasswordChangedImage from "../assets/password-change.png";
import Password from "../assets/password.png";
import passwordError from "../assets/ErrorPassword.png";
import email from "../assets/email.png";
import emailFailed from "../assets/ErrorEmail.png";
import inputSuccess from "../assets/input-success.png";
import inputSuccess2 from "../assets/success-input-white.png";
import inputFail from "../assets/input-fail.png";
import inputFail2 from "../assets/failed-input-white.png";
import phoneVerifyImg from "../assets/reset-password-phone.png";
import emailVerifyImg from "../assets/reset-password-email.png";

import Tooltip from "@mui/material/Tooltip";
/*
  ResetPassword.js:
  - Path: /resetPassword
  - Description: This is the page for resetting password with confirmation code
*/

const ResetPassword = () => {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const PASSWORD_LENGTH_REGEX = /^.{8,}$/;
  const PASSWORD_UPPER_CASE_REGEX = /^(?=.*[A-Z])/;
  const PASSWORD_LOWER_CASE_REGEX = /^(?=.*[a-z])/;
  const PASSWORD_SPECIAL_CHAR_REGEX = /^(?=.*[@$!%*#?&])/;
  const PASSWORD_ONE_NUMBER_REGEX = /\d/;
  const [mode, setMode] = useState("EMAIL");
  const [phoneDigits, setDigits] = useState("");
  const [resend, setResend] = useState(-1);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage1, setPasswordErrorMessage1] = useState("");
  const [passwordErrorMessage2, setPasswordErrorMessage2] = useState("");
  const [inputError, setInputError] = useState("");
  const [username, setUsername] = useState("");
  const [input, setInput] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [verifyBtn, setBtn] = useState(false);
  const [resetPasswordButtonEnabled, setResetPasswordButtonEnabled] =
    useState(false);

  const [tooltip, setTooltip] = useState(false);

  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [condition4, setCondition4] = useState(false);
  const [condition5, setCondition5] = useState(false);

  const history = useHistory();

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const RESET_API_PATH = "/request-reset-password";
  const CONFIRM_API_PATH = "/confirm-reset-password";

  const onRequestClick = (e) => {
    e.preventDefault();
    setInput(false);
    const username = document.getElementById("username").value;
    console.log("setting username");
    setUsername(username);
    requestReset(username);
  };

  const requestReset = async (username) => {
    const resetEndpoint = new URL(BASE_URL + RESET_API_PATH);
    const resetHeaders = {
      Username: username,
    };

    try {
      let response = await fetch(resetEndpoint, {
        method: "post",
        headers: resetHeaders,
      });
      const body = await response.json();
      const phoneNumber = body.data.Destination;
      setDigits("(***)-***-" + String(phoneNumber).slice(-4));
      if (response.status === 200) {
        setErrorState(false);
        setResend(resend + 1);
        const username = document.getElementById("username");
        username.value = "";
        setMode("CODE");
      }
    } catch (error) {}
  };

  const onConfirmClick = async (e) => {
    e.preventDefault();
    setResetPasswordButtonEnabled(false);
    const passwordNew = document.getElementById("newPassword").value;

    const resetEndpoint = new URL(BASE_URL + CONFIRM_API_PATH);
    const resetHeaders = {
      Username: username,
      "Confirmation-Code": resetCode,
      Password: passwordNew,
    };

    try {
      let response = await fetch(resetEndpoint, {
        method: "post",
        headers: resetHeaders,
      });
      switch (response.status) {
        case 200:
          setErrorState(false);
          setMode("SUCCESS");
          break;
        case 401:
          setErrorState(true);
          setErrorMessage("Invalid code. Please try again or resend the code.");
          setMode("CODE");
          setCode(["", "", "", "", "", ""]);
          break;
        case 409:
          setErrorState(true);
          setErrorMessage("Password not accepted");
          break;
        default:
          setErrorState(true);
          setErrorMessage("Server error. Please try again in a few minutes.");
      }
    } catch (error) {}
    setResetPasswordButtonEnabled(true);
    setBtn(true);
  };

  const goHome = () => {
    console.log("redirect to login");
    history.push("/login");
  };

  const onEmailChange = (e) => {
    e.preventDefault();
    const username = document.getElementById("username");
    const inputStatus = document.getElementById("email-status");
    const emailIcon = document.getElementById("email-icon-holder");
    inputStatus.hidden = false;
    if (EMAIL_REGEX.test(username.value)) {
      setInput(true);
      setInputError("");
      username.className = "request-reset-input";
      inputStatus.src = inputSuccess;
      emailIcon.src = email;
    } else {
      setInputError("Invalid email format.");
      setInput(false);
      username.className = "request-reset-input-failed";
      inputStatus.src = inputFail;
      emailIcon.src = emailFailed;
    }
  };

  const onCodeEnter = (e, i) => {
    setErrorState(false);
    setErrorMessage("");
    if (e.target.value.length > 1) {
      return;
    }
    const changed = code.map((c, j) => (i === j ? e.target.value : c));
    setCode(changed);
    if (changed[5] !== "") {
      setBtn(true);
    } else {
      setBtn(false);
    }
    if (
      e.target.value !== "" &&
      e.target.nextElementSibling &&
      e.target.nextElementSibling.nodeName === "INPUT"
    ) {
      e.target.nextElementSibling.focus();
    }
  };

  const onCodeBack = (e) => {
    if (
      e.target.value === "" &&
      e.keyCode === 8 &&
      e.target.previousElementSibling &&
      e.target.previousElementSibling.nodeName === "INPUT"
    ) {
      e.target.previousElementSibling.focus();
    }
  };

  const onCodeClick = async (e) => {
    e.preventDefault();
    setBtn(false);
    const codeVal = code.join("");
    setErrorState(false);
    setResetCode(codeVal);
    setMode("CONFIRM");
    document.querySelector("input").value = "";
  };

  const onNewPasswordChange = (e) => {
    e.preventDefault();
    //password 1
    const password = document.getElementById("newPassword");
    let inputStatus = document.getElementById("new-password-status");
    let inputIcon = document.getElementById("new-icon");

    inputStatus.hidden = false;

    setCondition1(PASSWORD_LENGTH_REGEX.test(password.value));
    setCondition2(PASSWORD_UPPER_CASE_REGEX.test(password.value));
    setCondition3(PASSWORD_LOWER_CASE_REGEX.test(password.value));
    setCondition4(PASSWORD_SPECIAL_CHAR_REGEX.test(password.value));
    setCondition5(PASSWORD_ONE_NUMBER_REGEX.test(password.value));

    if (PASSWORD_REGEX.test(password.value)) {
      setNewPassword(true);
      setPasswordErrorMessage1("");
      password.className = "reset-input-field";
      inputStatus.src = inputSuccess;
      inputIcon.src = Password;
      setResetPasswordButtonEnabled(confirmPassword);
    } else {
      setNewPassword(false);
      setPasswordErrorMessage1("Password must meet the criteria.");
      password.className = "reset-input-field-failed";
      inputStatus.src = inputFail;
      inputIcon.src = passwordError;
      setResetPasswordButtonEnabled(false);
    }

    //password 2
    const passwordNew = document.getElementById("newPassword");
    const passwordConfirm = document.getElementById("confirmPassword");
    inputStatus = document.getElementById("confirm-password-status");
    inputIcon = document.getElementById("confirm-icon");
    if (inputStatus.hidden === false) {
      if (passwordConfirm.value === "") {
        setConfirmPassword(false);
        setPasswordErrorMessage2("");
        inputStatus.hidden = true;
        passwordConfirm.className = "reset-input-field";
        inputIcon.src = Password;
        setResetPasswordButtonEnabled(false);
      } else if (passwordNew.value === passwordConfirm.value) {
        setConfirmPassword(true);
        setPasswordErrorMessage2("");
        passwordConfirm.className = "reset-input-field";
        inputStatus.src = inputSuccess;
        inputIcon.src = Password;
        setResetPasswordButtonEnabled(newPassword);
      } else {
        setConfirmPassword(false);
        setPasswordErrorMessage2("Password must match the previous entry.");
        passwordConfirm.className = "reset-input-field-failed";
        inputStatus.src = inputFail;
        inputIcon.src = passwordError;
        setResetPasswordButtonEnabled(false);
      }
    }
  };

  const onConfirmPasswordChange = (e) => {
    e.preventDefault();
    const passwordNew = document.getElementById("newPassword");
    const passwordConfirm = document.getElementById("confirmPassword");
    const inputStatus = document.getElementById("confirm-password-status");
    const inputIcon = document.getElementById("confirm-icon");
    inputStatus.hidden = false;
    if (passwordConfirm.value === "") {
      setConfirmPassword(false);
      setPasswordErrorMessage2("");
      inputStatus.hidden = true;
      passwordConfirm.className = "reset-input-field";
      inputIcon.src = Password;
      setResetPasswordButtonEnabled(false);
    } else if (passwordNew.value === passwordConfirm.value) {
      setConfirmPassword(true);
      setPasswordErrorMessage2("");
      passwordConfirm.className = "reset-input-field";
      inputStatus.src = inputSuccess;
      inputIcon.src = Password;
      setResetPasswordButtonEnabled(newPassword);
    } else {
      setConfirmPassword(false);
      setPasswordErrorMessage2("Password must match the previous entry.");
      passwordConfirm.className = "reset-input-field-failed";
      inputStatus.src = inputFail;
      inputIcon.src = passwordError;
      setResetPasswordButtonEnabled(false);
    }
  };

  const handleTooltipClose = (event) => {
    setTooltip(false);
  };

  const handleTooltipOpen = (event) => {
    setTooltip(true);
  };

  if (mode === "SUCCESS") {
    return (
      <>
        <PageHeader />
        <PasswordChanged onClick={goHome} buttonText="LOGIN" />
        <div className="request-reset-footer">
          <PageFooter />
        </div>
      </>
    );
  } else if (mode === "CONFIRM") {
    return (
      <>
        <PageHeader />
        <div className="main-reset-password">
          <div className="horizontal-line" />
          <div className="main-reset-container">
            <img
              className="reset-password-img"
              src={PasswordChangedImage}
              alt="Reset Changed"
            />
            <form
              id="reset-password-form"
              className="reset-password-form"
              onSubmit={onConfirmClick}
            >
              <h1 className="reset-password">Reset Password</h1>
              <div className="reset-input-row">
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="top-start"
                  arrow
                  title={
                    <>
                      <div className="reset-condition-row">
                        <img
                          id="condition-img-1"
                          className="reset-condition-img-holder"
                          alt=""
                          src={condition1 ? inputSuccess2 : inputFail2}
                        />
                        <span className="reset-condition-text">
                          At least 8 characters
                        </span>
                      </div>

                      <div className="reset-condition-row">
                        <img
                          id="condition-img-2"
                          className="reset-condition-img-holder"
                          alt=""
                          src={condition2 ? inputSuccess2 : inputFail2}
                        />
                        <span className="reset-condition-text">
                          At least one upper case letter
                        </span>
                      </div>

                      <div className="reset-condition-row">
                        <img
                          id="condition-img-3"
                          className="reset-condition-img-holder"
                          alt=""
                          src={condition3 ? inputSuccess2 : inputFail2}
                        />
                        <span className="reset-condition-text">
                          At least one lower case letter
                        </span>
                      </div>

                      <div className="reset-condition-row">
                        <img
                          id="condition-img-5"
                          className="reset-condition-img-holder"
                          alt=""
                          src={condition5 ? inputSuccess2 : inputFail2}
                        />
                        <span className="reset-condition-text">
                          At least one number
                        </span>
                      </div>

                      <div className="reset-condition-row">
                        <img
                          id="condition-img-4"
                          className="reset-condition-img-holder"
                          alt=""
                          src={condition4 ? inputSuccess2 : inputFail2}
                        />
                        <span className="reset-condition-text">
                          At least one special character
                        </span>
                      </div>
                    </>
                  }
                >
                  <input
                    data-tip
                    data-for="pass1-tip"
                    data-event="focusin"
                    className="reset-input-field"
                    id="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    onInput={onNewPasswordChange}
                    onFocus={handleTooltipOpen}
                    onBlur={handleTooltipClose}
                  ></input>
                </Tooltip>
                <span className="reset-input-img">
                  <img src={Password} id="new-icon" alt="Password Icon" />
                </span>
                <span className="reset-input-validate">
                  <img id="new-password-status" alt="" hidden={true} />
                </span>
              </div>
              <div className="password-errorMessage-small">
                {passwordErrorMessage1}
              </div>
              <div className="reset-input-row-last">
                <input
                  className="reset-input-field"
                  id="confirmPassword"
                  type="password"
                  placeholder="Re-enter new password"
                  onInput={onConfirmPasswordChange}
                ></input>
                <span className="reset-input-img">
                  <img src={Password} id="confirm-icon" alt="Password Icon" />
                </span>
                <span className="reset-input-validate">
                  <img id="confirm-password-status" alt="" hidden={true} />
                </span>
              </div>
              <div className="password-errorMessage-small">
                {passwordErrorMessage2}
              </div>
              <div className="password-errorMessage-main">{errorMessage}</div>
              <button
                type="submit"
                className="reset-btn"
                disabled={!resetPasswordButtonEnabled}
              >
                CONFIRM
              </button>
            </form>
          </div>
          <div className="horizontal-line" />
        </div>
        <div className="request-reset-footer">
          <PageFooter footerIndex />
        </div>
      </>
    );
  } else if (mode === "CODE") {
    return (
      <>
        <PageHeader />
        <div className="main-reset-code">
          <div className="horizontal-line" />
          <div className="reset-code-container">
            <img
              className="reset-code-image"
              alt="phone-verify"
              src={phoneVerifyImg}
            />
            <form
              id="reset-code-form"
              className="reset-code-form"
              onSubmit={onCodeClick}
              autoComplete="off"
            >
              <h1>Reset Password</h1>
              <h3>
                We sent a 6-digit code to your phone <br />
                {phoneDigits}. Please enter it below.
              </h3>
              <div
                className={
                  errorState
                    ? "reset-code-code error-message"
                    : "reset-code-code"
                }
              >
                <input
                  id="verification_code_1"
                  inputMode="tel"
                  value={code[0]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 0)}
                />
                <input
                  id="verification_code_2"
                  inputMode="tel"
                  value={code[1]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 1)}
                />
                <input
                  id="verification_code_3"
                  inputMode="tel"
                  value={code[2]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 2)}
                />
                <input
                  id="verification_code_4"
                  inputMode="tel"
                  value={code[3]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 3)}
                />
                <input
                  id="verification_code_5"
                  inputMode="tel"
                  value={code[4]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 4)}
                />
                <input
                  id="verification_code_6"
                  inputMode="tel"
                  value={code[5]}
                  onKeyDown={onCodeBack}
                  onChange={(e) => onCodeEnter(e, 5)}
                />
                <p>{errorState && <span>{errorMessage}</span>}</p>
                <button
                  disabled={!verifyBtn}
                  className="reset-code-verify-btn"
                  type="submit"
                >
                  VERIFY
                </button>
                <button
                  className="reset-code-resend-btn"
                  type="reset"
                  onClick={() => requestReset(username)}
                >
                  Resend the code
                </button>
              </div>
            </form>
          </div>
          <div className="horizontal-line" />
        </div>
        <div className="request-reset-footer">
          <PageFooter footerIndex />
        </div>
      </>
    );
  } else {
    return (
      <>
        <PageHeader />
        <div className="main-reset-code">
          <div className="horizontal-line" />
          <div className="reset-code-container">
            <img
              className="reset-code-image"
              alt="phone-verify"
              src={emailVerifyImg}
            />

            <form
              data-testid="reset-pass-form"
              onSubmit={onRequestClick}
              className="request-reset-form"
            >
              <h1>Forgot Password</h1>
              <h3 className="row-h3-spacing">
                Please enter the email address associated with your account.
              </h3>
              <div className="request-reset-input-row">
                <div className="request-reset-inputbox">
                  <input
                    className="request-reset-input"
                    type="email"
                    id="username"
                    placeholder="Enter email address"
                    onInput={onEmailChange}
                  ></input>
                  <span className="input-img">
                    <img id="email-icon-holder" src={email} alt="Email Icon" />
                  </span>
                  <span className="input-validate">
                    <img id="email-status" hidden={true} alt="" />
                  </span>
                </div>
                <div className="input-error-email">{inputError}</div>
              </div>
              <button
                className="reset-form-request-reset-btn"
                type="submit"
                disabled={!input}
              >
                NEXT
              </button>
            </form>
          </div>
          <div className="horizontal-line" />
        </div>
        <div className="request-reset-footer">
          <PageFooter footerIndex />
        </div>
      </>
    );
  }
};

export default ResetPassword;
