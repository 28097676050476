/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState } from "react";
import LandingPageHeader from "../components/LandingPageHeader.js";
import PageFooter from "../components/PageFooter.js";
import "./SignUp.css";
import email from "../assets/email.png";
import emailError from "../assets/ErrorEmail.png";
import phone from "../assets/phone.png";
import phoneError from "../assets/ErrorPhone.png";
import password from "../assets/password.png";
import passwordError from "../assets/ErrorPassword.png";
import inputSuccess from "../assets/input-success.png";
import inputSuccess2 from "../assets/success-input-white.png";
import inputFail from "../assets/input-fail.png";
import inputFail2 from "../assets/failed-input-white.png";
import { useHistory } from "react-router-dom";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import Tooltip from "@mui/material/Tooltip";

/*
  SignUp.js:
  - Path: /signup
  - Description: This is the page for signing up in ANNE Sleep Hub
*/
const SignUp = () => {
  const [errorState, setErrorState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailErrorMessage1, setEmailErrorMessage1] = useState(null);
  const [phoneErrorMessage1, setPhoneErrorMessage1] = useState(null);
  const [phoneErrorMessage2, setPhoneErrorMessage2] = useState(null);
  const [passwordErrorMessage1, setPasswordErrorMessage1] = useState(null);
  const [passwordErrorMessage2, setPasswordErrorMessage2] = useState(null);
  const [input1, setInput1] = useState(false);
  const [input2, setInput2] = useState(false);
  const [input3, setInput3] = useState(false);
  const [input4, setInput4] = useState(false);
  const [input5, setInput5] = useState(false);
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [condition4, setCondition4] = useState(false);
  const [condition5, setCondition5] = useState(false);
  const [signupButtonEnabled, setSignupButtonEnabled] = useState(false);
  const history = useHistory();

  const [tooltip, setTooltip] = useState(false);

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const PHONE_REGEX = /\+1\s[2-9]{1}\d{2}-\d{3}-\d{4}$/;
  const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const REGISTER_PATH = "/register";
  const PASSWORD_LENGTH_REGEX = /^.{8,}$/;
  const PASSWORD_UPPER_CASE_REGEX = /^(?=.*[A-Z])/;
  const PASSWORD_LOWER_CASE_REGEX = /^(?=.*[a-z])/;
  const PASSWORD_SPECIAL_CHAR_REGEX = /^(?=.*[@$!%*#?&])/;
  const PASSWORD_ONE_NUMBER_REGEX = /\d/;

  const onSignUpClick = async (e) => {
    e.preventDefault();
    setSignupButtonEnabled(false);
    if (input1 && input2 && input3 && input4 && input5) {
      console.log("Correct Inputs");
      const username = document.getElementById("email-1");
      const password = document.getElementById("password-1");
      const phonenumber = document.getElementById("phonenumber-1");
      const phonenumberValue = phonenumber.value.replace(/-\s/g, "");
      const endpoint = new URL(
        process.env.REACT_APP_API_BASE_URL + REGISTER_PATH
      );
      const headers = {
        email: username.value.toLowerCase(),
        secret: password.value,
        mobile: phonenumberValue,
      };
      fetch(endpoint, {
        method: "POST",
        headers: headers,
      })
        .then((response) => {
          if (response.status === 200) {
            history.push({
              pathname: "/confirmation-code",
              username: username.value.toLowerCase(),
              password: password.value,
              phonenumber: phonenumber.value,
            });
          } else if (response.status === 401) {
            setSignupButtonEnabled(false);
            setErrorState(true);
            setErrorMessage("Please try use a different password.");
          } else if (response.status === 404) {
            setSignupButtonEnabled(false);
            setErrorState(true);
            var errorMessage = (
              <>
                <span>
                  Please complete the{" "}
                  <a
                    className="signup-qualification"
                    href="/qualification-survey"
                  >
                    qualification test
                  </a>{" "}
                  first.
                </span>
              </>
            );
            setErrorMessage(errorMessage);
          } else if (response.status === 409) {
            setSignupButtonEnabled(false);
            setErrorState(true);
            setErrorMessage("Sorry, you are not qualified for this study.");
          } else {
            setSignupButtonEnabled(false);
            setErrorState(true);
            setErrorMessage(
              "Oops! Something went wrong with the server. Please try again in a few minutes"
            );
          }
        })
        .catch((error) => {
          setSignupButtonEnabled(false);
          setErrorState(true);
          setErrorMessage(
            "Oops! Something went wrong with the server. If you are using a VPN, please disable it. If the problem persists, try again in a few minutes."
          );
        });
    } else {
      console.log("Some Inputs are Incorrect or Missing");
    }
  };

  const onEmail1Change = (e) => {
    e.preventDefault();
    const username = document.getElementById("email-1");
    const inputStatus = document.getElementById("email-1-status");
    const emailIcon = document.getElementById("email1");
    setErrorMessage();
    inputStatus.hidden = false;
    if (EMAIL_REGEX.test(username.value.toLowerCase())) {
      setInput1(true);
      username.className = "signup-inputfield";
      emailIcon.src = email;
      inputStatus.src = inputSuccess;
      inputStatus.alt = "input-status-1-success";
      setEmailErrorMessage1(null);
      setSignupButtonEnabled(input2 && input3 && input4 && input5);
    } else {
      setInput1(false);
      username.className = "signup-inputfield-failed";
      emailIcon.src = emailError;
      inputStatus.src = inputFail;
      inputStatus.alt = "input-status-1-failed";
      setEmailErrorMessage1("Invalid email format.");
      setSignupButtonEnabled(false);
    }
  };

  const onPhone1Change = () => {
    setErrorMessage();
    // phone 1
    var phoneNumber = document.getElementById("phonenumber-1");
    let inputStatus = document.getElementById("phonenumber-1-status");
    let phoneIcon = document.getElementById("phonenumber1");

    inputStatus.hidden = false;
    if (PHONE_REGEX.test(phoneNumber.value)) {
      setInput2(true);
      phoneNumber.className = "signup-inputfield";
      phoneIcon.src = phone;
      inputStatus.src = inputSuccess;
      inputStatus.alt = "input-status-3-success";
      setPhoneErrorMessage1(null);
      setSignupButtonEnabled(input1 && input3 && input4 && input5);
    } else {
      setInput2(false);
      phoneNumber.className = "signup-inputfield-failed";
      phoneIcon.src = phoneError;
      inputStatus.src = inputFail;
      inputStatus.alt = "input-status-3-failed";
      setPhoneErrorMessage1("Must be a valid US phone number.");
      setSignupButtonEnabled(false);
    }

    // phone 2
    const phoneNumber2 = document.getElementById("phonenumber-2");
    phoneIcon = document.getElementById("phonenumber2");
    inputStatus = document.getElementById("phonenumber-2-status");

    if (inputStatus.hidden === false) {
      if (
        PHONE_REGEX.test(phoneNumber2.value) &&
        phoneNumber2.value === phoneNumber.value
      ) {
        setInput3(true);
        phoneNumber2.className = "signup-inputfield";
        phoneIcon.src = phone;
        inputStatus.src = inputSuccess;
        inputStatus.alt = "input-status-4-success";
        setPhoneErrorMessage2(null);
        setSignupButtonEnabled(input1 && input2 && input4 && input5);
      } else {
        setInput3(false);
        phoneNumber2.className = "signup-inputfield-failed";
        phoneIcon.src = phoneError;
        inputStatus.src = inputFail;
        inputStatus.alt = "input-status-4-failed";
        setPhoneErrorMessage2("Phone number must match the previous entry.");
        setSignupButtonEnabled(false);
      }
    }
  };

  const onPhone2Change = () => {
    setErrorMessage();
    const phoneNumber = document.getElementById("phonenumber-1");
    const phoneNumber2 = document.getElementById("phonenumber-2");
    const phoneIcon = document.getElementById("phonenumber2");
    const inputStatus = document.getElementById("phonenumber-2-status");

    inputStatus.hidden = false;
    if (
      PHONE_REGEX.test(phoneNumber2.value) &&
      phoneNumber2.value === phoneNumber.value
    ) {
      setInput3(true);
      phoneNumber2.className = "signup-inputfield";
      phoneIcon.src = phone;
      inputStatus.src = inputSuccess;
      inputStatus.alt = "input-status-4-success";
      setPhoneErrorMessage2(null);
      setSignupButtonEnabled(input1 && input2 && input4 && input5);
    } else {
      setInput3(false);
      phoneNumber2.className = "signup-inputfield-failed";
      phoneIcon.src = phoneError;
      inputStatus.src = inputFail;
      inputStatus.alt = "input-status-4-failed";
      setPhoneErrorMessage2("Phone number must match the previous entry.");
      setSignupButtonEnabled(false);
    }
  };

  const onPassword1Change = (e) => {
    e.preventDefault();
    setErrorMessage();

    // password 1
    const password1 = document.getElementById("password-1");
    let passwordIcon = document.getElementById("password1");
    let inputStatus = document.getElementById("password-1-status");

    inputStatus.hidden = false;

    setCondition1(PASSWORD_LENGTH_REGEX.test(password1.value));
    setCondition2(PASSWORD_UPPER_CASE_REGEX.test(password1.value));
    setCondition3(PASSWORD_LOWER_CASE_REGEX.test(password1.value));
    setCondition4(PASSWORD_SPECIAL_CHAR_REGEX.test(password1.value));
    setCondition5(PASSWORD_ONE_NUMBER_REGEX.test(password1.value));

    if (PASSWORD_REGEX.test(password1.value)) {
      setInput4(true);
      password1.className = "signup-inputfield";
      passwordIcon.src = password;
      inputStatus.src = inputSuccess;
      inputStatus.alt = "input-status-5-success";
      setPasswordErrorMessage1(null);
      setSignupButtonEnabled(input1 && input2 && input3 && input5);
    } else {
      setInput4(false);
      password1.className = "signup-inputfield-failed";
      passwordIcon.src = passwordError;
      inputStatus.src = inputFail;
      inputStatus.alt = "input-status-5-failed";
      setPasswordErrorMessage1("Password must meet the criteria.");
      setSignupButtonEnabled(false);
    }

    // password 2
    const password2 = document.getElementById("password-2");
    passwordIcon = document.getElementById("password2");
    inputStatus = document.getElementById("password-2-status");
    if (inputStatus.hidden === false) {
      if (password2.value.length && password2.value === password1.value) {
        setInput5(true);
        password2.className = "signup-inputfield";
        passwordIcon.src = password;
        inputStatus.src = inputSuccess;
        inputStatus.alt = "input-status-6-success";
        setPasswordErrorMessage2(null);
        setSignupButtonEnabled(
          input1 && input2 && input3 && PASSWORD_REGEX.test(password1.value)
        );
      } else {
        setInput5(false);
        password2.className = "signup-inputfield-failed";
        passwordIcon.src = passwordError;
        inputStatus.src = inputFail;
        inputStatus.alt = "input-status-6-failed";
        setPasswordErrorMessage2("Password must match the previous entry.");
        setSignupButtonEnabled(false);
      }
    }
  };

  const onPassword2Change = (e) => {
    e.preventDefault();
    setErrorMessage();
    const password1 = document.getElementById("password-1");
    const password2 = document.getElementById("password-2");
    const passwordIcon = document.getElementById("password2");
    const inputStatus = document.getElementById("password-2-status");

    inputStatus.hidden = false;
    if (password2.value.length && password2.value === password1.value) {
      setInput5(true);
      password2.className = "signup-inputfield";
      passwordIcon.src = password;
      inputStatus.src = inputSuccess;
      inputStatus.alt = "input-status-6-success";
      setPasswordErrorMessage2(null);
      setSignupButtonEnabled(input1 && input2 && input3 && input4);
    } else {
      setInput5(false);
      password2.className = "signup-inputfield-failed";
      passwordIcon.src = passwordError;
      inputStatus.src = inputFail;
      inputStatus.alt = "input-status-6-failed";
      setPasswordErrorMessage2("Password must match the previous entry.");
      setSignupButtonEnabled(false);
    }
  };

  const onLogin = () => {
    console.log("Redirecting to login");
    history.push({
      pathname: "/login",
    });
  };

  const handleTooltipClose = (event) => {
    setTooltip(false);
  };

  const handleTooltipOpen = (event) => {
    setTooltip(true);
  };

  return (
    <>
      <LandingPageHeader text="Log in" onButtonClick={onLogin} />
      <div className="signup-main">
        <div className="signup-banner">
          <div className="signup-container">
            <div className="signup-col-1">
              <h2 className="signup-title">Sleep Better Feel Better</h2>
              <p className="signup-description">
                Begin your study journey with five simple steps.
              </p>
              <div className="signup-Stepper">
                <ul className="signup-stepper-list">
                  <li className="signup-stepper-list-item">
                    <div className="signup-stepper-circle">1</div>
                    <div className="signup-stepper-title">Sign-up (1 min)</div>
                  </li>
                  <li className="signup-stepper-list-item">
                    <div className="signup-stepper-circle">2</div>
                    <div className="signup-stepper-title">Verify (1 min)</div>
                  </li>
                  <li className="signup-stepper-list-item">
                    <div className="signup-stepper-circle">3</div>
                    <div className="signup-stepper-title">Consent (5 mins)</div>
                  </li>
                  <li className="signup-stepper-list-item">
                    <div className="signup-stepper-circle">4</div>
                    <div className="signup-stepper-title">Survey (2 mins)</div>
                  </li>
                  <li className="signup-stepper-list-item">
                    <div className="signup-stepper-circle">5</div>
                    <div className="signup-stepper-title">Schedule (1 min)</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="signup-col-2">
              <div className="signup-box">
                <form className="signup-form" onSubmit={onSignUpClick}>
                  <p className="signup-form-des">
                    Welcome to
                    <br />
                    ANNE™ Sleep Hub
                  </p>
                  <div className="signup-row">
                    <div className="input-box">
                      <div className="signup-inputbox">
                        <input
                          className="signup-inputfield"
                          id="email-1"
                          placeholder="Enter email address"
                          type="text"
                          onInput={onEmail1Change}
                        ></input>
                        <span className="signup-img">
                          <img src={email} id="email1" alt="Email" />
                        </span>
                        <span className="signup-input-validate">
                          <img id="email-1-status" hidden={true} alt="" />
                        </span>
                      </div>
                      {emailErrorMessage1 && (
                        <div className="signup-error-text">
                          {emailErrorMessage1}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <div className="signup-inputbox">
                        <MaskedTextBoxComponent
                          id="phonenumber-1"
                          placeholder="Enter cell phone number"
                          type="tel"
                          className="signup-inputfield"
                          mask="+1 000-000-0000"
                          onChange={onPhone1Change}
                        ></MaskedTextBoxComponent>
                        <span className="signup-img">
                          <img src={phone} id="phonenumber1" alt="Phone" />
                        </span>
                        <span className="signup-input-validate">
                          <img id="phonenumber-1-status" hidden={true} alt="" />
                        </span>
                      </div>
                      {phoneErrorMessage1 && (
                        <div className="signup-error-text">
                          {phoneErrorMessage1}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <div className="signup-inputbox">
                        <MaskedTextBoxComponent
                          className="signup-inputfield"
                          id="phonenumber-2"
                          placeholder="Re-enter cell phone number"
                          type="tel"
                          mask="+1 000-000-0000"
                          onChange={onPhone2Change}
                        ></MaskedTextBoxComponent>
                        <span className="signup-img">
                          <img src={phone} id="phonenumber2" alt="Phone" />
                        </span>
                        <span className="signup-input-validate">
                          <img id="phonenumber-2-status" hidden={true} alt="" />
                        </span>
                      </div>
                      {phoneErrorMessage2 && (
                        <div className="signup-error-text">
                          {phoneErrorMessage2}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <div className="signup-inputbox">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={tooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-start"
                          arrow
                          title={
                            <>
                              <div className="signup-condition-row">
                                <img
                                  id="condition-img-1"
                                  className="signup-condition-img-holder"
                                  alt=""
                                  src={condition1 ? inputSuccess2 : inputFail2}
                                />
                                <span className="signup-condition-text">
                                  At least 8 characters
                                </span>
                              </div>

                              <div className="signup-condition-row">
                                <img
                                  id="condition-img-2"
                                  className="signup-condition-img-holder"
                                  alt=""
                                  src={condition2 ? inputSuccess2 : inputFail2}
                                />
                                <span className="signup-condition-text">
                                  At least one upper case letter
                                </span>
                              </div>

                              <div className="signup-condition-row">
                                <img
                                  id="condition-img-3"
                                  className="signup-condition-img-holder"
                                  alt=""
                                  src={condition3 ? inputSuccess2 : inputFail2}
                                />
                                <span className="signup-condition-text">
                                  At least one lower case letter
                                </span>
                              </div>

                              <div className="signup-condition-row">
                                <img
                                  id="condition-img-5"
                                  className="signup-condition-img-holder"
                                  alt=""
                                  src={condition5 ? inputSuccess2 : inputFail2}
                                />
                                <span className="signup-condition-text">
                                  At least one number
                                </span>
                              </div>

                              <div className="signup-condition-row">
                                <img
                                  id="condition-img-4"
                                  className="signup-condition-img-holder"
                                  alt=""
                                  src={condition4 ? inputSuccess2 : inputFail2}
                                />
                                <span className="signup-condition-text">
                                  At least one special character
                                </span>
                              </div>
                            </>
                          }
                        >
                          <input
                            className="signup-inputfield pass-format"
                            id="password-1"
                            placeholder="Enter password"
                            type="password"
                            onInput={onPassword1Change}
                            onFocus={handleTooltipOpen}
                            onBlur={handleTooltipClose}
                          ></input>
                        </Tooltip>
                        <span className="signup-img">
                          <img src={password} id="password1" alt="Password" />
                        </span>
                        <span className="signup-input-validate">
                          <img id="password-1-status" hidden={true} alt="" />
                        </span>
                      </div>
                      {passwordErrorMessage1 && (
                        <div className="signup-error-text">
                          {passwordErrorMessage1}
                        </div>
                      )}
                    </div>
                    <div className="input-box">
                      <div className="signup-inputbox">
                        <input
                          className="signup-inputfield pass-format"
                          id="password-2"
                          placeholder="Re-enter password"
                          type="password"
                          onInput={onPassword2Change}
                        ></input>
                        <span className="signup-img">
                          <img src={password} id="password2" alt="Password" />
                        </span>
                        <span className="signup-input-validate">
                          <img id="password-2-status" hidden={true} alt="" />
                        </span>
                      </div>
                      {passwordErrorMessage2 && (
                        <div className="signup-error-text">
                          {passwordErrorMessage2}
                        </div>
                      )}
                      {errorState && (
                        <div className="signup-error-text-server">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    disabled={!signupButtonEnabled}
                    className="signup-btn"
                    type="submit"
                  >
                    SIGN UP
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="signup-footer">
        <PageFooter descriptionColor />
      </div>
    </>
  );
};

export default SignUp;
