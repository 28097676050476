import React from "react";
import "./PasswordChanged.css";
import phoneVerifyImg from "../assets/password-changed.png";

const PasswordChanged = (props) => {
  return (
    <div className="main-password-changed">
      <div className="horizontal-line" />
      <div className="password-changed-container">
        <img
          className="password-changed-image"
          alt="phone-verify"
          src={phoneVerifyImg}
        />
        <form
          className="password-changed-form"
          onSubmit={props.onClick}
          autoComplete="off"
        >
          <h1>Password Changed</h1>
          <h3>Success! Your password has been changed.</h3>
          <button className="password-changed-verify-btn" type="submit">
            {props.buttonText}
          </button>
        </form>
      </div>
      <div className="horizontal-line" />
    </div>
  );
};

export default PasswordChanged;
