/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React from "react";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import PageResponse from "../components/PageResponse.js";
import PassQualification from "../assets/Pass-Qualification.png";
import { useHistory } from "react-router-dom";
/*
  Qualified.js:
  - Path: None
  - Description: This is the page for Qualified survey status
*/

const Qualified = () => {
  const history = useHistory();

  if (history.location.search !== "?redirect=false") {
    history.push({
      pathname: "/",
    });
  }

  const onClickGoSignup = () => {
    history.push({
      pathname: "/signup",
    });
  };

  let description = (
    <h3>
      You qualify for our sleep study. Next, sign-up to learn more about our
      study before you enroll.
    </h3>
  );

  return (
    <>
      <PageHeader />
      {/* Inner content component start */}
      <PageResponse
        imageURL={PassQualification}
        alt="Pass Qualification"
        title="Congratulations!"
        description={description}
        onClick={onClickGoSignup}
        btntext="SIGN UP"
      />
      {/* Inner content component End */}
      <PageFooter />
    </>
  );
};

export default Qualified;
