/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React from "react";
import "node-fetch";
import "./TermsPopup.css";
import CloseIcon from "../assets/icon_close.png";

/*
  TermsPopup.js:
  - Path: None
  - Description: This is the component that creates a popup with a specific data for the terms
*/

const TermsPopup = ({ trigger, title, setTrigger }) => {
  let textVal;
  if (title === "Privacy Policy") {
    textVal = (
      <div>
        <div className="sub-1">I. General</div>
        <div className="sub-2">
          This Privacy Policy describes the types of personal information we
          collect from the users of the services (“Services”) provided by us
          through the mobile application and any related websites, including
          associated firmware, applications, software, websites, APIs, products,
          and services. This Privacy Policy also explains how Sibel receives,
          uses, discloses, and otherwise processes your personal data.
        </div>

        <div className="sub-1">II. Information we collect</div>
        <div className="sub-2">
          We may collect the following categories of information through our
          Services:
        </div>

        <div className="sub-3">Contact information</div>
        <div className="sub-2">
          Your first and last name, email address, mailing address, and phone
          number may be collected during the registration process.
        </div>

        <div className="sub-3">Sensor Information</div>
        <div className="sub-2">
          Sensor information includes information gathered from sensors such as
          ECG, PPG, motion data, and temperature data.
        </div>

        <div className="sub-3">Health Information</div>
        <div className="sub-2">
          Health information includes information such as your height, weight,
          past medical history, and health symptoms. This information may be
          collected through in-app surveys and the web portal.
        </div>

        <div className="sub-3">Device, Technical, and Usage Information</div>
        <div className="sub-2">
          When you use our Services, we may collect information about your
          device, which in many cases is non-personal information. We also may
          generate usage statistics about your interactions with usage
          information and technical data, i.e., data in a form that does not, on
          its own, permit direct association with any specific individual.
          Technical data includes, for example, information about your app usage
          (e.g., when the app was first launched), app version and installation
          ID, device identifier, and technical data about your device, such as
          operating system and model.
        </div>

        <div className="sub-3">Adverse event information</div>
        <div className="sub-2">
          We may collect adverse event information such as a concern, adverse
          event, or other reportable matter arising during use of our Services.
        </div>

        <div className="sub-1">III. How we use your information</div>
        <div className="sub-2">
          We may retain, use, and share limited, de-identified health-related
          information for research purposes. We will remove or code any personal
          information that could identify you before files are shared with other
          researchers to ensure that, by current scientific standards and known
          methods, no one will be able to identify you from the information we
          share. Despite these measures, we cannot guarantee anonymity of your
          personal data.
        </div>

        <div className="sub-1">IV. How Information is Shared</div>
        <div className="sub-2">
          We do not share your personal information except in the limited
          circumstances described below.
        </div>

        <div className="sub-3">For Legal Reasons to Prevent Harm</div>
        <div className="sub-2">
          We may preserve or disclose information about you to comply with a
          law, regulation, legal process, or governmental request; to assert
          legal rights or defend against legal claims; or to prevent, detect, or
          investigate illegal activity, fraud, abuse, violations of our terms,
          or threats to the security of the Services or the physical safety of
          any person.
        </div>
        <div className="sub-2">
          Please note that our policy is to notify you of legal processes
          seeking access to your information, such as search warrants, court
          orders, or subpoenas, unless we are prohibited by law from doing so.
          In cases where a court order specifies a non-disclosure period, we
          provide delayed notice after the expiration of the non-disclosure
          period. Exceptions to our notice policy include exigent or
          counterproductive circumstances, for example, when there is an
          emergency involving a danger of death or serious physical injury to a
          person.
        </div>

        <div className="sub-3">Other Disclosures</div>
        <div className="sub-2">
          We may share non-personal information that is aggregated and
          de-identified so that it cannot reasonably be used to identify an
          individual. We may disclose such information publicly and to third
          parties, for example, in public reports and to partners under
          agreement with us.
        </div>
        <div className="sub-2">
          If we are involved in a merger, acquisition, or sale of assets, we
          will continue to take measures to protect the confidentiality of
          personal information and give affected users notice before
          transferring any personal information to a new entity. We may sell,
          transfer or otherwise share some or all of our business or assets,
          including personal information, in connection with a business
          transaction such as a merger, consolidation, acquisition, spinoff,
          reorganization or sale of assets or in the event of bankruptcy. We
          will require any recipient of data in connection with such business
          transactions to abide by this Privacy Policy.
        </div>

        <div className="sub-1">V. Protection of Your Information</div>
        <div className="sub-2">
          Sibel has implemented a number of security measures to protect your
          information and to protect the confidentiality of your information,
          including encrypting all personal information in transit and at rest.
          We are not a healthcare provider or other “covered entity” that is
          subject to the Health Insurance Portability and Accountability Act of
          1996 (HIPAA) and are not bound by its requirements for handling
          protected health information. However, we have a security program in
          place designed to mitigate risk and to use reasonable and appropriate
          procedures and technologies to help protect the confidentiality of all
          personal information. We use a combination of technical,
          administrative, and physical controls to maintain the security of your
          data. If you have a security-related concern, please contact us at
          support@sibelhealth.com.
        </div>

        <div className="sub-1">VI. Retention of Personal Information</div>
        <div className="sub-2">
          We may retain information regarding you and your use of our Services,
          including Personal Data, for as long as reasonably needed to provide
          you with the Services and the uses described in this Privacy Policy.
        </div>
        <div className="sub-2">
          Your use of our Services is voluntary. You may decide not to use our
          Services at any time. If you decide to discontinue the use of our
          Services, we may not delete the information that we have already
          collected and we may continue to use it, but we will stop receiving
          any new data about you. You may delete your user account at any time
          by contacting us at support@sibelhealth.com. We may retain and
          continue to use de-identified data.
        </div>

        <div className="sub-1">Children</div>
        <div className="sub-2">
          Our Services are not intended for children under the age of 18 or
          equivalent minimum age in the relevant jurisdiction. We do not
          knowingly collect any personal information from children under 13. If
          we learn that we have collected the personal information of a child
          under 13, we will take steps to delete the information as soon as
          possible from our live systems and apps, unless we are prohibited by
          law from doing so. We will delete any personal information we maintain
          on back-up media in the ordinary course of business.
        </div>

        <div className="sub-1">Changes to Our Privacy Policy</div>
        <div className="sub-2">
          We may update this Privacy Policy from time to time. We may need to
          modify our privacy practices to comply with legal requirements, such
          as court orders, subpoenas, or investigations. If we make material
          changes to our Privacy Policy, notice will be provided to you for the
          updated Privacy Policy and the effective change date.
        </div>
        <div className="sub-2">
          If you object to the updated Privacy Policy, we will continue
          processing your information in accordance with the Privacy Policy to
          which you agreed or delete your account and information if we cannot
          do so.
        </div>
        <div className="sub-2">
          By continuing to use our Services thirty (30) days after we notify you
          of our updated Privacy Policy, you will have agreed to the new Privacy
          Policy.
        </div>

        <div className="sub-1">Contact Us</div>
        <div className="sub-2">
          If you have any feedback, questions, comments, or concerns relating to
          this Privacy Policy or our privacy practices, please send an email to
          support@sibelhealth.com or write to us at the following address:
        </div>
        <div className="sub-2">
          Sibel Inc., 6650 W Touhy Ave., Niles IL, 60714
        </div>
      </div>
    );
  } else if (title === "Terms of Use") {
    textVal = (
      <div>
        <div className="sub-2">
          These Terms of Service (“Terms”) are a legal contract between You and
          Sibel Inc., located at 6650 W Touhy Ave, Niles, Illinois 60714, United
          States, for use only under the terms of this Agreement. These Terms
          apply to your access and use of the services (“Services”) provided by
          us through the mobile application and any related websites, including
          associated firmware, applications, software, websites, APIs, products,
          and services. By using the Services, you acknowledge that you have
          read, understood, and agree, to be bound by these terms and to comply
          with all applicable laws and regulations. If you do not agree to these
          terms, do not use the Services.
        </div>
        <div className="sub-2">
          By using the Services, you expressly represent and warrant that you
          are legally entitled to agree to these Terms. If you reside in a
          jurisdiction that restricts the use of the Services because of age, or
          restricts the ability to enter into agreements such as this one due to
          age, you must abide by such age limits and you must not use the
          Services. Without limiting the foregoing, the Services are not
          available to children (persons under the age of 18). By using the
          Services, you represent and warrant that you are at least 18 years
          old. By using the Services, you represent and warrant that you have
          the right, authority and capacity to enter into these Terms and to
          abide by the terms and conditions of these Terms.
        </div>

        <div className="sub-1">General Use</div>
        <div className="sub-2">
          Subject to your agreement and compliance with the Terms, we grant you
          non-exclusive, non-transferable, non-sharable, revocable, limited
          licenses to use the Services (including the Software in connection
          with the Services) solely for your personal, non-commercial use to the
          Services. Your right to use the Services and the Services is
          conditioned on Your compliance with these Terms. You have no other
          rights in the Services and You may not modify, edit, copy, reproduce,
          create derivative works of, reverse engineer, alter, enhance or in any
          way exploit anything in the Services in any manner.
        </div>
        <div className="sub-2">
          To access and use the Services, you must register for a user account
          (“Account”) by providing your email, a valid phone number, a password,
          and any other information indicated as required. When you create an
          account with us, you must provide us information that is accurate,
          complete, and current at all times. We may reject your application for
          an Account, or cancel an existing Account, for any reason, at our sole
          discretion. Keep your user Account information secure and do not share
          them with anyone. You are responsible for all activities on the
          Services associated with your Account.
        </div>
        <div className="sub-2">
          If You breach any of these Terms the above license will terminate
          automatically and You must immediately stop accessing and using the
          Services.
        </div>

        <div className="sub-1">Changes to the Services</div>
        <div className="sub-2">
          We may alter the Services We offer You and/or choose to modify,
          suspend, or discontinue the Services at any time and without notifying
          You. We are not liable to you or to any third party for any
          modification, suspension, or discontinuance of any feature, component,
          or content of the Services. We reserve the right to determine the
          timing and content of software updates, which may be automatically
          downloaded and installed by Sibel products without prior notice to
          you. If You object to any such modifications, Your sole recourse shall
          be to cease using the Services. Continued use of the Services
          following notice of any such modifications indicates You acknowledge
          and agree to be bound by the modifications.
        </div>

        <div className="sub-1">Changes to the Terms</div>
        <div className="sub-2">
          We reserve the right, at our discretion, to change the Terms on a
          going forward basis at any time. Your continued use of the Services
          after the changes become effective constitutes your binding acceptance
          of such changes. In the event that a change to the Terms has been
          made, we will make an effort to notify you of the change, such as by
          sending you an email to the email address we have on file for you, or
          presenting a notification to you through the Services. If you do not
          agree to the amended Terms, you must stop accessing and using the
          Services.
        </div>

        <div className="sub-1">Mobile Device Terms</div>
        <div className="sub-2">
          To use some of our Services You must have a compatible mobile device.
          We do not warrant that the Services will be compatible with Your
          mobile device. We hereby grant to You a non-exclusive,
          non-transferable, revocable license to use an object code copy of the
          Services for one registered account on one mobile device owned or
          leased solely by You, for Your personal use. You may not: (i) modify,
          disassemble, decompile or reverse engineer the Services, except to the
          extent that such restriction is expressly prohibited by law; (ii)
          rent, lease, loan, resell, sublicense, distribute or otherwise
          transfer the Services to any third-party or use the Services to
          provide time sharing or similar services for any third-party; (iii)
          make any copies of the Services; (iv) remove, circumvent, disable,
          damage or otherwise interfere with security-related features of the
          Services, features that prevent or restrict use or copying of any
          content accessible through the Services, or features that enforce
          limitations on use of the Services; or (v) delete the copyright and
          other proprietary rights notices on the Services. You acknowledge that
          We may from time to time issue upgraded versions of the Services, and
          may automatically electronically upgrade the version of the Services
          that You are using on Your mobile device. You consent to such
          automatic upgrading on Your mobile device, and agree that these Terms
          will apply to all such upgrades. The foregoing license grant is not a
          sale of the Services or any copy thereof, and We and Our third-party
          licensors or suppliers retain all right, title, and interest in and to
          the Services (and any copy of the Services). Standard carrier data
          charges may apply to Your use of the Services.
        </div>

        <div className="sub-1">iOS</div>
        <div className="sub-2">
          The following additional terms and conditions apply with respect to
          any App that We provide to You designed for use on an Apple
          iOS-powered mobile device (an “iOS App”):
        </div>
        <ul>
          <li>
            You acknowledge that these Terms are between You and Us only, and
            not with Apple, Inc. (“Apple”).
          </li>
          <li>
            Your use of Our iOS App must comply with Apple’s then-current App
            Store Terms of Service.
          </li>
          <li>
            We, and not Apple, are solely responsible for Our iOS App and the
            App and Content available thereon. You acknowledge that Apple has no
            obligation to provide maintenance and support services with respect
            to Our iOS App. To the maximum extent permitted by applicable law,
            Apple will have no warranty obligation whatsoever with respect to
            Our iOS App.
          </li>
          <li>
            You agree that We, and not Apple, are responsible for addressing any
            claims by You or any third-party relating to Our iOS App or Your
            possession and/or use of Our iOS App, including, but not limited to:
            (i) product liability claims; (ii) any claim that the iOS App fails
            to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar
            legislation, and all such claims are governed solely by these Terms
            and any law applicable to Us as provider of the iOS App.
          </li>
          <li>
            You agree that We, and not Apple, shall be responsible, to the
            extent required by these Terms, for the investigation, defense,
            settlement and discharge of any third-party intellectual property
            infringement claim related to Our iOS App or Your possession and use
            of Our iOS App.
          </li>
          <li>
            You represent and warrant that (i) You are not located in a country
            that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a “terrorist supporting”
            country; and (ii) You are not listed on any U.S. Government list of
            prohibited or restricted parties.
          </li>
          <li>
            You agree to comply with all applicable third-party terms of
            agreement when using Our iOS App (e.g., You must not be in violation
            of Your wireless data service terms of agreement when using the iOS
            App).
          </li>
          <li>
            The parties agree that Apple and Apple’s subsidiaries are
            third-party beneficiaries to these Terms as they relate to Your
            license of Our iOS App. Upon Your acceptance of these Terms, Apple
            will have the right (and will be deemed to have accepted the right)
            to enforce these Terms against You as they relate to Your license of
            the iOS App as a third-party beneficiary thereof.
          </li>
        </ul>

        <div className="sub-1">Android</div>
        <div className="sub-2">
          The following additional terms and conditions apply with respect to
          any App that We provide to You designed for use on an Android-powered
          mobile device (an “Android App”):
        </div>
        <ul>
          <li>
            You acknowledge that these Terms are between You and Us only, and
            not with Google, Inc. (“Google”).
          </li>
          <li>
            Your use of Our Android App must comply with Google’s then-current
            Android Market Terms of Service.
          </li>
          <li>
            Google is only a provider of the Android Market where You obtained
            the Android App. We, and not Google, are solely responsible for Our
            Android App and the App and Content available thereon. Google has no
            obligation or liability to You with respect to Our Android App or
            these Terms.
          </li>
          <li>
            You acknowledge and agree that Google is a third-party beneficiary
            to the Terms as they relate to Our Android App.
          </li>
        </ul>

        <div className="sub-1">Links</div>
        <div className="sub-2">
          We may provide third-party links as a convenience.Links to third-party
          sites are not under the control of Us and We are not responsible for
          the content of any linked site. We are not obligated to review any
          third-party websites that You link to from the Services, We do not
          control any of the third-party websites, and We are not responsible
          for any of the third-party websites (or the products, services, or
          content available through any of them). Thus, We do not endorse or
          make any representations about such third-party websites, any
          information, software, products, services, or materials found there or
          any results that may be obtained from using them. If You decide to
          access any of the third-party websites linked to from the Services,
          You do this entirely at Your own risk and You must follow the privacy
          policies and terms and conditions for those third-party websites.
        </div>

        <div className="sub-1">Prohibited Uses</div>
        <div className="sub-2">
          You agree to use the Services in accordance with these Terms and
          applicable laws and regulations. You will not violate any applicable
          law, contract, intellectual property or other third-party right or
          commit a tort, and you are solely responsible for your conduct while
          accessing or using the Services. In connection with your use of the
          Services, you will not:
        </div>
        <ul>
          <li>
            use the Services in a manner that violates any local, state,
            national, foreign, or international statute, regulation, rule,
            order, treaty, or other law.
          </li>
          <li>
            use the Services in a manner that modifies, publicly displays,
            publicly performs, reproduces or distributes any of the Services.
          </li>
          <li>
            impersonate another person or entity or otherwise misrepresent Your
            affiliation with a person or entity; or log into an account which
            you are not authorized to access.
          </li>
          <li>
            use the Services in any manner that could interfere with, disrupt,
            negatively affect or inhibit other users from fully accessing or
            using the Services.
          </li>
          <li>
            reverse engineer, translate, disassemble, integrate, decompile,
            integrate, remove, modify, combine, create derivative works or
            updates of, adapt, or attempt to derive the source code of the
            application, or any part thereof (except with our prior written
            consent).
          </li>
          <li>
            attempt to gain unauthorized access to any portion of the Services
            or any other accounts, computer systems, or networks connected to
            the Services, whether through hacking, password mining, or any other
            means.
          </li>
          <li>
            develop or use any third-party devices applications that interact
            with the Services without our written prior consent.
          </li>
        </ul>

        <div className="sub-1">Intellectual Property Rights</div>
        <div className="sub-2">
          Services are protected by copyright, trademark, patent, intellectual
          property, and other laws of the United States and foreign countries.
          We reserve all rights not expressly set forth in these Terms. You
          agree not to remove, change or obscure any copyright, trademark,
          service mark, or other proprietary rights notices incorporated in or
          accompanying the Services. Our logos and any other Sibel trademarks
          that may appear in the Services, and the overall look and feel of the
          Services, including graphics, icons, and scripts, may not be copied,
          imitated or used, in whole or in part, without our prior written
          permission. Other trademarks, product, and service names and company
          names or logos mentioned in the Services are the property of their
          respective owners and may not be copied, imitated or used, in whole or
          in part, without the permission of the applicable trademark holder.
        </div>

        <div className="sub-1">Disclaimer of Warranties</div>
        <div className="sub-2">
          WE DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SERVICE WILL BE
          SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION
          WITH ANY OTHER HARDWARE, APP, SYSTEM OR DATA, (B) THE SERVICE OR
          PROVIDER WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED
          DATA WILL BE ACCURATE OR RELIABLE, (D) ERRORS OR DEFECTS IN THE
          SERVICE WILL BE CORRECTED, OR (F) THE SERVICE OR THE SERVER(S) THAT
          MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS. THE SERVICE IS PROVIDED TO YOU STRICTLY ON AN “AS IS”
          BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER
          EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE
          HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY
          US. WE MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE
          RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY
          OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK
          ARISING OUT OF YOUR USE OF THE SERVICE, AND ANY HEALTHCARE SERVICES
          RENDERED BY ANY PROVIDERS OR RECEIVED BY YOU REMAIN SOLELY WITH YOU,
          TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </div>

        <div className="sub-1">Limitation of Liability</div>
        <div className="sub-2">
          TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, SIBEL DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          SIBEL WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
          USE OF THIS APP OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS
          (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE
          AVAILABLE TO YOU THROUGH THE SERVICES, INCLUDING, BUT NOT LIMITED TO
          DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA, FAILURE TO
          TRANSMIT OR RECEIVE ANY DATA, BUSINESS INTERRUPTION OR ANY OTHER
          COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR
          INABILITY TO USE THE SERVICES OR ANY THIRD PARTY SOFTWARE OR
          APPLICATIONS IN CONJUNCTION WITH THE SERVICES, UNLESS OTHERWISE
          SPECIFIED IN WRITING. ALTHOUGH WE MAY PROVIDE ACCESS TO PROVIDERS, WE
          WILL NOT ASSESS THE SUITABILITY OR ABILITY OF SUCH PROVIDERS AND YOU
          EXPRESSLY WAIVE AND RELEASE US FROM ANY AND ALL ANY LIABILITY, CLAIMS
          OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO THE HEALTHCARE
          SERVICES RENDERED OR NOT RENDERED BY PROVIDERS. SOME JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
          OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </div>

        <div className="sub-1">Termination</div>
        <div className="sub-2">
          We reserve the right to suspend or deactivate your Account or to
          terminate these Terms, as determined in the sole discretion of us, at
          any time and without notice or liability to you. You may close your
          Account at any time by contacting Customer Support at
          support@sibelhealth.com. Upon termination of this License, You shall
          stop all use of the Services.
        </div>

        <div className="sub-1">Applicable Law</div>
        <div className="sub-2">
          This license agreement is governed by the laws of the State of
          Illinois excluding its conflicts of law rules.
        </div>

        <div className="sub-1">Privacy Policy</div>
        <div className="sub-2">
          For more information about our data practices, please see our Privacy
          Policy. By accessing or using the Services, you agree that we can
          collect and use your information in accordance with our Privacy
          Policy.
        </div>

        <div className="sub-1">Miscellaneous</div>
        <div className="sub-2">
          If any of the Terms should be or become invalid, the validity of the
          remaining provisions shall not be affected. Invalid terms will be
          replaced by valid ones formulated in a way that will achieve the
          primary purpose. Collateral agreements, changes and amendments are
          only valid if laid down in writing. The preceding clause can only be
          waived in writing.
        </div>

        <div className="sub-1">Contact Us</div>
        <div className="sub-2">
          If you have any feedback, questions, comments, or concerns relating to
          these Terms, please send an email to support@sibelhealth.com or write
          to us at the following address:
        </div>
        <div className="sub-2">
          Sibel Inc., 6650 W Touhy Ave., Niles IL, 60714
        </div>
      </div>
    );
  }
  const closePopup = () => {
    document.body.style.overflow = "auto";
    setTrigger(false);
  };
  if (trigger) {
    return (
      <div className="terms-popup">
        <div className="terms-popup-inner">
          <img
            className="terms-close-btn"
            src={CloseIcon}
            onClick={closePopup}
            alt="close"
          ></img>
          <div className="popup-title">{title}</div>

          <div className="terms-inner-data">
            <div className="terms-inner-data-text">{textVal}</div>
          </div>
        </div>
      </div>
    );
  } else return "";
};

export default TermsPopup;
