import { refreshAndCallWithIdToken } from "./credentialsHelper";
import "regenerator-runtime/runtime";
import "node-fetch";

const UPDATE_STATUS_PATH = "/status"

export const surveyToIdMap = {
  survey_consent_quiz: "SV_3O5gR1oDitjMsaq",
  survey_consent_sign: "SV_cYmWPdTVhL5qOZo",
  pre_trial_survey: "SV_5u2zLA76VmCMK7Y",
  post_test_survey_1: "SV_1Mq4LY42sX4lfzo",
  post_test_survey_2: "SV_8CzvTmBLjJ7304m",
}

export const idToSurveyMap = {
  SV_3O5gR1oDitjMsaq: "survey-consent-quiz",
  SV_cYmWPdTVhL5qOZo: "survey-consent-sign",
  SV_5u2zLA76VmCMK7Y: "survey-pre-trial",
  SV_1Mq4LY42sX4lfzo: "survey-post-trial-1",
  SV_8CzvTmBLjJ7304m: "survey-post-trial-2",
}

export const statusToPageMap = {
  "survey-consent-quiz": "/survey",
  "survey-consent-sign": "/survey",
  "survey-pre-trial": "/survey",
  "address-and-date": "/address-date",
  "dashboard": "/dashboard",
  "survey-post-trial-1": "/dashboard",
  "survey-post-trial-2": "/dashboard",
}

export const updateUserStatus = (credentials, currentStatus, callback = undefined) => {
  var nextState = "";
  switch (currentStatus) {
    case "survey-consent-quiz":
      nextState = "survey-consent-sign";
      break;
    case "survey-consent-sign":
      nextState = "survey-pre-trial";
      break;
    case "survey-pre-trial":
      nextState = "address-and-date";
      break;
    case "address-and-date":
      nextState = "dashboard";
      break;
    case "dashboard":
      nextState = "survey-post-trial-1";
      break;
    case "survey-post-trial-1":
      nextState = "survey-post-trial-2";
      break;
    case "survey-post-trial-2":
      nextState = "survey-post-trial-2";
      break;
    default:
      console.log("No corresponding state");
  }

  refreshAndCallWithIdToken(credentials, function(accessToken, idToken) {
    callUserStatusUpdate(accessToken, idToken, nextState, callback);
  });
};

const callUserStatusUpdate = async (accessToken, idToken, status, callback) => {
  const endpoint = new URL(
    process.env.REACT_APP_API_BASE_URL + UPDATE_STATUS_PATH
  );
  const headers = {
    "Access-Token": accessToken,
    Authorization: idToken,
    Status: status
  };
  try {
    let response = await fetch(endpoint, {
      method: "POST",
      headers: headers,
    });
    let data = await response.json();
    await data.message;
    // console.log(data);
    if (callback !== undefined) {
      callback();
    }
  } catch (error) {
    // console.log(error);
  }
}
