/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useState } from "react";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import { Redirect } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { storeCredentials } from "../redux/actions";
import UserFlowBar from "../components/UserFlowBar.js";
import phoneVerifyImg from "../assets/phone-verify-img.png";
import phoneVerifiedImg from "../assets/phone-verified-img.png";
import "./ConfirmationCode.css";

/*
  ConfirmationCode.js:
  - Path: /confirmation-code
  - Description: This is the page for phone number verification with
    the confirmation code in cases:
        - After a successful user sign-up in /sign-up
        - When a user's phone number is not verified during log-in in /login
*/

const ConfirmationCode = () => {
  const CONFIRM_AND_LOGIN_API_PATH = "/confirm-and-login";

  const [errorState, setErrorState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [verifyBtn, setBtn] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const history = useHistory();
  const location = useLocation();
  const { username, password, phonenumber } = location;

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const RESEND_API_PATH = "/resend-confirmation-code";

  const dispatch = useDispatch();

  const onClickResendCode = async (e) => {
    e.preventDefault();
    const resendCodeEndpoint = new URL(BASE_URL + RESEND_API_PATH);
    const resendCodeHeaders = {
      Username: username,
    };
    try {
      let response = await fetch(resendCodeEndpoint, {
        method: "post",
        headers: resendCodeHeaders,
      });
      if (response.status === 200) {
        setCode(["", "", "", "", "", ""]);
        setErrorState(false);
      }
    } catch (error) {}
  };

  const onVerificationCodeClick = (e) => {
    e.preventDefault();
    setBtn(false);
    const verificationCodeVal = code.join("");
    if (verificationCodeVal.length !== 6) {
      setCode(["", "", "", "", "", ""]);
      setErrorState(true);
      setErrorMessage("Invalid code. Please try again or resend the code.");
    } else {
      let endpoint = new URL(
        process.env.REACT_APP_API_BASE_URL + CONFIRM_AND_LOGIN_API_PATH
      );
      let headers = {
        "Confirmation-Code": verificationCodeVal,
        Username: username,
        Password: password,
      };
      fetch(endpoint, { method: "post", headers: headers })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.data) {
            const authResult = data.data.AuthenticationResult;
            dispatch(storeCredentials(authResult));
            setVerificationStatus(true);
          } else {
            setCode(["", "", "", "", "", ""]);
            setErrorState(true);
            setErrorMessage(
              "Invalid code. Please try again or resend the code."
            );
          }
        });
    }
  };

  const onEnterClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/survey",
    });
  };

  const onCodeEnter = (e, i) => {
    if (e.target.value.length > 1) {
      return;
    }
    const changed = code.map((c, j) => (i === j ? e.target.value : c));
    setCode(changed);

    if (
      changed[0] !== "" &&
      changed[0] !== " " &&
      changed[1] !== "" &&
      changed[1] !== " " &&
      changed[2] !== "" &&
      changed[2] !== " " &&
      changed[3] !== "" &&
      changed[3] !== " " &&
      changed[4] !== "" &&
      changed[4] !== " " &&
      changed[5] !== "" &&
      changed[5] !== " "
    ) {
      setBtn(true);
    } else {
      setBtn(false);
    }
    if (
      e.target.value !== "" &&
      e.target.nextElementSibling &&
      e.target.nextElementSibling.nodeName === "INPUT"
    ) {
      e.target.nextElementSibling.focus();
    }
  };

  const onCodeBack = (e) => {
    if (
      e.target.value === "" &&
      e.keyCode === 8 &&
      e.target.previousElementSibling &&
      e.target.previousElementSibling.nodeName === "INPUT"
    ) {
      e.target.previousElementSibling.focus();
    }
  };

  if (!username || !password) {
    return <Redirect to="/login" />;
  }
  if (verificationStatus === true) {
    return (
      <>
        <PageHeader />
        <div className="main-confirmationcode">
          <UserFlowBar stage="verify" />
          <div className="confirmationcode-container">
            <img
              className="confirmationcode-image"
              alt="phone-verified"
              src={phoneVerifiedImg}
            />
            <form
              data-testid="confirmation_form"
              className="confirmationcode-form"
              onSubmit={onEnterClick}
            >
              <h1>Phone Verified</h1>
              <h3>
                Congratulations! Your Phone has successfully been verified.
              </h3>
              <input
                className="confirmationcode-verify-btn"
                type="submit"
                value="NEXT"
              />
            </form>
          </div>
        </div>
        <div className="confirmationcode-footer">
          <PageFooter />
        </div>
      </>
    );
  }
  return (
    <>
      <PageHeader />
      <div className="main-confirmationcode">
        <UserFlowBar stage="verify" />
        <div className="confirmationcode-container">
          <img
            className="confirmationcode-image"
            alt="phone-verify"
            src={phoneVerifyImg}
          />
          <form
            data-testid="code_form"
            className="confirmationcode-form"
            autoComplete="off"
            onSubmit={onVerificationCodeClick}
          >
            <h1>Verify Your Phone</h1>
            <h3>
              We sent a 6-digit code to your phone
              {phonenumber
                ? " (***)-***-" + String(phonenumber).slice(-4)
                : " number"}
              . Please enter it below.
            </h3>
            <div
              className={
                errorState
                  ? "confirmationcode-code error-message"
                  : "confirmationcode-code"
              }
            >
              <input
                data-testid="code_1"
                id="verification_code_1"
                inputMode="tel"
                value={code[0]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 0)}
              />
              <input
                data-testid="code_2"
                id="verification_code_2"
                inputMode="tel"
                value={code[1]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 1)}
              />
              <input
                data-testid="code_3"
                id="verification_code_3"
                inputMode="tel"
                value={code[2]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 2)}
              />
              <input
                data-testid="code_4"
                id="verification_code_4"
                inputMode="tel"
                value={code[3]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 3)}
              />
              <input
                data-testid="code_5"
                id="verification_code_5"
                inputMode="tel"
                value={code[4]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 4)}
              />
              <input
                data-testid="code_6"
                id="verification_code_6"
                inputMode="tel"
                value={code[5]}
                onKeyDown={onCodeBack}
                onChange={(e) => onCodeEnter(e, 5)}
              />
              <p>{errorState && <div>{errorMessage}</div>}</p>
            </div>
            <div className="confirmationcode-buttons">
              <button
                disabled={!verifyBtn}
                className="confirmationcode-verify-btn"
                type="submit"
              >
                VERIFY
              </button>
              <button
                data-testid="resend-btn"
                className="confirmationcode-resend-btn"
                onClick={(e) => onClickResendCode(e)}
              >
                Resend the code
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="confirmationcode-footer">
        <PageFooter />
      </div>
    </>
  );
};

export default ConfirmationCode;
