/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import { useHistory } from "react-router-dom";
import React from "react";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";
import PageResponse from "../components/PageResponse.js";
import failedSurvey from "../assets/failed-survey-new.png";
import "./NotQualified.css";

/*
  NotQualified.js:
  - Path: None
  - Description: This is the page for Not Qualified survey status
*/

const NotQualified = () => {
  const history = useHistory();

  if (history.location.search !== "?redirect=false") {
    history.push({
      pathname: "/",
    });
  }

  let description = (
    <div className="qualified-container">
      <div className="qualified-header">
        We appreciate your interest and are sorry that you can't join this
        study.
      </div>
      <br />
      <div className="qualified-body">
        Please see your primary care physician if you need to be assessed for
        obstructive sleep apnea.
      </div>
      <br />
      <div className="qualified-body">
        Alternatively, you may{" "}
        <strong>see a primary care physician virtually</strong> by:
      </div>
      <ol className="qualified-list">
        <li className="qualified-body">
          Logging into your Anthem benefits through the Sydney app.
        </li>
        <li className="qualified-body">
          Initiating a virtual visit with a provider or calling the 24/7 nurse
          line number on the back of your card.
        </li>
      </ol>
    </div>
  );

  let descriptionTwo = <div></div>;

  return (
    <>
      <PageHeader />
      {/* Inner content component start */}
      <PageResponse
        imageURL={failedSurvey}
        alt="Failed Survey"
        description={description}
        descriptionTwo={descriptionTwo}
      />
      {/* Inner content component End */}
      <PageFooter />
    </>
  );
};

export default NotQualified;
